import DOMPurify from 'dompurify';
import marked from 'marked';
import React from 'react';
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import NavInfo from '../components/NavInfo';
import { useGetAboutQuery } from '../generated/graphql';

const LOREM_IPSUM =
	'Morbi purus libero, faucibus adipiscing, commodo quis, gravida id, est. Sed lectus. Praesent elementum hendrerit tortor. Sed semper lorem at felis. Vestibulum volutpat, lacus a ultrices sagittis, mi neque euismod dui, eu pulvinar nunc sapien ornare nisl. Phasellus pede arcu, dapibus eu';

const AboutScreen: React.FC = () => {
	const { data } = useGetAboutQuery();

	return (
		<>
			<Nav />
			<section className="lg:px-24 md:px-16 px-4">
				<NavInfo path={['Inicio', 'Paginas', 'Somos']} />
				<div
					className="relative w-full text-center mb-12"
					style={{
						minHeight: '300px',
						backgroundSize: 'cover',
						backgroundPosition: 'center center',
						backgroundImage: `url(${
							(data?.paginaSomos &&
								data.paginaSomos[0]?.BannerImagen &&
								data.paginaSomos[0]?.BannerImagen[0] &&
								data.paginaSomos[0]?.BannerImagen[0].url) ??
							'https://d-themes.com/react/molla/demo-6/assets/images/about-header-bg.jpg'
						})`,
					}}
				>
					<div className="absolute w-full top-1/2 px-3 text-white ">
						<h1 className="text-2xl mb-3 mx-auto">
							{(data?.paginaSomos &&
								data.paginaSomos[0]?.TituloImagen) ??
								'Acerca de Nosotros'}
						</h1>
						<p className="text-lg mb-3">
							{(data?.paginaSomos &&
								data.paginaSomos[0]?.SubtituloImagen) ??
								'Quienes Somos'}
						</p>
					</div>
				</div>
				<div className="mx-auto text-base w-full">
					<div // eslint-disable-next-line react/no-danger
						dangerouslySetInnerHTML={{
							__html: marked(
								(data?.paginaSomos &&
									data.paginaSomos[0]?.TextoPrincipal) ??
									LOREM_IPSUM,
								{
									sanitize: true,
									sanitizer: DOMPurify.sanitize,
									smartLists: true,
									xhtml: true,
									silent: true,
									gfm: true,
								},
							),
						}}
					/>
				</div>
				<div className="flex flex-wrap -mx-2 overflow-hidden">
					<div className="my-2 px-2 w-full overflow-hidden md:w-1/2">
						<h3 className="text-2xl text-black mb-2">Visión</h3>
						<div className="mb-6 text-sm text-gray-500">
							<div // eslint-disable-next-line react/no-danger
								dangerouslySetInnerHTML={{
									__html: marked(
										(data?.paginaSomos &&
											data.paginaSomos[0]?.TextoVision) ??
											LOREM_IPSUM,
										{
											sanitize: true,
											sanitizer: DOMPurify.sanitize,
											smartLists: true,
											xhtml: true,
											silent: true,
											gfm: true,
										},
									),
								}}
							/>
						</div>
					</div>
					<div className="my-2 px-2 w-full overflow-hidden md:w-1/2">
						<div className="mb-6 text-sm text-gray-500">
							<h3 className="text-2xl text-black mb-2">Misión</h3>
							<div // eslint-disable-next-line react/no-danger
								dangerouslySetInnerHTML={{
									__html: marked(
										(data?.paginaSomos &&
											data.paginaSomos[0]?.TextoMision) ??
											LOREM_IPSUM,
										{
											sanitize: true,
											sanitizer: DOMPurify.sanitize,
											smartLists: true,
											xhtml: true,
											silent: true,
											gfm: true,
										},
									),
								}}
							/>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default AboutScreen;
