import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import SwiperCore, { Autoplay, Navigation } from 'swiper/core';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import ProductScreen from './screens/ProductScreen';
import CartScreen from './screens/CartScreen';
import ContactScreen from './screens/ContactScreen';
import LandScreen from './screens/LandScreen';
import NotFoundScreen from './screens/NotFoundScreen';
import ShopScreen from './screens/ShopScreen';
import TermsScreen from './screens/TermsScreen';
import CheckoutScreen from './screens/CheckoutScreen';
import AboutScreen from './screens/AboutScreen';
import FAQScreen from './screens/FAQScreen';
import PrivacyScreen from './screens/PrivacyScreen';
// import BlogScreen from './screens/BlogScreen';

import 'swiper/swiper-bundle.min.css';
import 'swiper/components/navigation/navigation.min.css';
import NotificationContainer from './services/Notifications';

SwiperCore.use([Navigation, Autoplay]);

const client = new ApolloClient({
	uri:
		process.env.REACT_APP_GRAPHQL_ENDPOINT ??
		'https://administracionerosshop.com/graphql',
	cache: new InMemoryCache({}),
});

const App: React.FC = () => {
	return (
		<ApolloProvider client={client}>
			<NotificationContainer>
				<BrowserRouter>
					<Switch>
						<Route path="/" exact component={LandScreen} />
						<Route path="/terminos" exact component={TermsScreen} />
						<Route
							path="/privacidad"
							exact
							component={PrivacyScreen}
						/>
						<Route path="/faq" exact component={FAQScreen} />
						<Route path="/somos" exact component={AboutScreen} />
						<Route
							path={[
								'/tienda',
								'/tienda/mujer',
								'/tienda/hombre',
								'/tienda/:category',
								'/promos',
							]}
							strict
							exact
							component={ShopScreen}
						/>
						{/* <Route path="/blog" exact component={BlogScreen} /> */}
						<Route
							path="/contacto"
							exact
							component={ContactScreen}
						/>
						<Route path="/cart" exact component={CartScreen} />
						<Route
							path="/checkout"
							exact
							component={CheckoutScreen}
						/>
						<Route
							path="/producto/:name"
							exact
							component={ProductScreen}
						/>

						<Route path="/" component={NotFoundScreen} />
					</Switch>
				</BrowserRouter>
			</NotificationContainer>
		</ApolloProvider>
	);
};

export default App;
