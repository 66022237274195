/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import marked from 'marked';
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import NavInfo from '../components/NavInfo';
import { useGetFaqQuery } from '../generated/graphql';

type ToggleCardProps = {
	title: string;
	text: string;
	className?: string;
};
const ToggleCard: React.FC<ToggleCardProps> = ({
	title,
	text,
	className,
}: ToggleCardProps) => {
	const [active, setActive] = useState(false);

	return (
		<button
			type="button"
			className={`group relative px-6 pt-3 ${className} focus:outline-none `}
			tabIndex={-1}
			onClick={() => setActive(!active)}
		>
			<div className="flex">
				<span
					className={`inline-block text-left text-base font-medium pb-3 ${
						active ? 'text-main-orange' : 'text-black'
					} hover:text-main-orange`}
				>
					{title}
				</span>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="currentColor"
					className={`inline-block ml-auto w-4 h-4 transform transition-all ${
						active ? 'text-main-orange rotate-180' : 'text-black'
					}`}
					viewBox="0 0 16 16"
				>
					<path
						fillRule="evenodd"
						d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
					/>
				</svg>
			</div>
			<div
				className={`text-left text-gray-500 transition-all duration-1000 text-sm ${
					// eslint-disable-next-line no-nested-ternary
					active ? (text.length < 255 ? 'h-36' : 'h-72') : 'h-0'
				}`}
				// eslint-disable-next-line react/no-danger
				dangerouslySetInnerHTML={{
					__html: marked(text, {
						sanitize: true,
						sanitizer: DOMPurify.sanitize,
						smartLists: true,
						xhtml: true,
						silent: true,
						gfm: true,
					}),
				}}
			/>
		</button>
	);
};
ToggleCard.defaultProps = {
	className: '',
};

const FAQScreen: React.FC = () => {
	const { data, loading } = useGetFaqQuery();

	return (
		<>
			<Nav />
			<section className="">
				<div
					className="relative w-full text-center"
					style={{
						minHeight: '170px',
						backgroundSize: 'cover',
						backgroundPosition: 'center center',
						backgroundImage:
							'url("https://res.cloudinary.com/kurtcovayne4/image/upload/v1617494988/page-header-bg_s8p66k.jpg")',
					}}
				>
					<div className="absolute w-full top-1/4 px-3">
						<h1 className="text-5xl mb-3 mx-auto">F.A.Q</h1>
						<p className="text-xl text-main-orange mb-3">Eros</p>
					</div>
				</div>
				<div className="w-full lg:px-24 md:px-14 px-2">
					<NavInfo path={['Inicio', 'Shop', 'Tienda']} />
					<span className="block border-b w-full h-px mb-8 border-gray-200" />
					{!data && loading && (
						<h3 className="text-2xl font-semibold mb-4">
							Cargando...
						</h3>
					)}
					{data?.tituloFaqs?.map((tituloFaq) => (
						<div
							className="w-full text-center mb-4"
							key={tituloFaq?.id}
						>
							<h3 className="text-2xl font-semibold mb-4">
								{tituloFaq?.Nombre}
							</h3>
							<div className="mb-12 w-full">
								<div className="flex flex-col relative bg-white overflow-hidden">
									{tituloFaq?.preguntas_respuestas?.map(
										(pr) => (
											<ToggleCard
												key={pr?.Pregunta}
												title={pr?.Pregunta ?? ''}
												text={pr?.Respuesta ?? ''}
												className="bg-gray-50 border rounded-t"
											/>
										),
									)}
								</div>
							</div>
						</div>
					))}
				</div>
			</section>

			<Footer />
		</>
	);
};

export default FAQScreen;
