/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import NavInfo from '../components/NavInfo';
import Product from '../components/Product';
import { useGetProductsQuery } from '../generated/graphql';
import '../styles/Cart.css';

const ShopScreen: React.FC = () => {
	const location = useLocation();
	const [where, setWhere] = useState<true | Record<string, unknown>>(true);
	const { data, loading } = useGetProductsQuery({
		variables: { limit: 300, where },
	});

	const [productsCut, setProductsCut] = useState(15);

	const spinner = useRef<HTMLDivElement>(null);

	const loadMore = useCallback(
		async (entries: IntersectionObserverEntry[]) => {
			const target = entries[0];
			if (
				target.isIntersecting &&
				!loading &&
				typeof data?.productos !== 'undefined'
			) {
				setProductsCut((pc) =>
					Math.min(pc + 16, data.productos?.length ?? Infinity),
				);
			}
		},
		[data, loading],
	);

	useEffect(() => {
		const options = {
			root: null, // window by default
			rootMargin: '0px',
			threshold: 0.25,
		};

		const observer = new IntersectionObserver(loadMore, options);

		if (spinner && spinner.current) {
			observer.observe(spinner.current);
		}

		const lastProductRef = spinner.current;
		return () => {
			if (lastProductRef) observer.unobserve(lastProductRef);
		};
	}, [spinner, loadMore]);

	useEffect(() => {
		const subPath = location.pathname.split('/').slice(-1)[0].toLowerCase();
		if (subPath === 'promos') {
			setWhere({ Descuento: true, salePrice_gte: 0 });
		} else if (subPath === 'hombre') {
			setWhere({ categoria: { Hombre: true } });
		} else if (subPath === 'mujer') {
			setWhere({ categoria: { Mujer: true } });
		} else if (subPath === 'tienda') {
			setWhere(true);
		} else {
			setWhere({ categoria: { Nombre_contains: subPath } });
		}
	}, [setWhere, location]);

	return (
		<>
			<Nav />
			<section className="relative w-full h-full">
				<div
					className="relative w-full text-center"
					style={{
						minHeight: '170px',
						backgroundSize: 'cover',
						backgroundPosition: 'center center',
						backgroundImage:
							'url("https://res.cloudinary.com/kurtcovayne4/image/upload/v1617494988/page-header-bg_s8p66k.jpg")',
					}}
				>
					<div className="absolute w-full top-1/4 px-3">
						<h1 className="text-5xl mb-3 mx-auto">Tienda</h1>
						<p className="text-xl text-main-orange mb-3">Tienda</p>
					</div>
				</div>
				<div className="w-full lg:px-32 md:px-24 px-4 mb-12">
					<NavInfo path={['Inicio', 'Tienda', 'Tienda']} />
					<span className="block border-b  absolute left-0 h-px w-full border-gray-300" />
					<div className="flex flex-wrap mt-2 mb-4">
						<div className="w-full md:w-4/12 md:ml-0 ml-24">
							<span className="text-gray-400 text">
								Mostrando{' '}
								<span className="text-black">
									{data?.productos?.length &&
										(data.productos.length < productsCut
											? data?.productos?.length
											: productsCut)}
								</span>{' '}
								de{' '}
								<span className="text-black">
									{data?.productos?.length}
								</span>{' '}
							</span>
						</div>
					</div>
					<div className="flex flex-wrap overflow-hidden -mx-1">
						{!data && loading ? (
							<div>Cargando productos...</div>
						) : (
							<>
								{data?.productos?.length === 0 && (
									<h1 className="display-1">
										Todavía no hay productos
									</h1>
								)}
								{data?.productos?.map((product) => (
									<>
										{product && (
											<Product
												key={product.id}
												id={product.id ?? '1000'}
												name={product.Titulo ?? ''}
												selectedColor={
													(product.colores &&
														product.colores[0]
															?.NombreColor) ??
													null
												}
												image={
													(product.Imagenes &&
														product.Imagenes[0] &&
														product.Imagenes[0]
															.url) ??
													'https://res.cloudinary.com/kurtcovayne4/image/upload/v1621286530/plain-gray-background_e1ff71.jpg'
												}
												categories={
													product.categoria?.Nombre ??
													''
												}
												price={product.precio}
												topText={
													(product.Descuento
														? 'Descuento'
														: undefined) ||
													(product.Nuevo
														? 'Nuevo'
														: undefined)
												}
												SKU={product.SKU ?? ''}
											/>
										)}
									</>
								))}
								<div className="my-4">
									{data?.productos &&
										data.productos.length > productsCut && (
											<div
												ref={spinner}
												className="spinner-border text-light"
												role="status"
											>
												<span className="visually-hidden">
													Cargando...
												</span>
											</div>
										)}
								</div>
							</>
						)}
					</div>
				</div>
			</section>

			<Footer />
		</>
	);
};

export default ShopScreen;
