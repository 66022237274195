import { useCallback, useEffect, useState } from 'react';

// Hook
const cerr = (message: string): void => {
	if (process.env.NODE_ENV === 'development') {
		// eslint-disable-next-line no-console
		console.error(message);
	}
};

const useLocalStorage = <T>(
	key: string,
	defaultValue: T | null | undefined = null,
): [Readonly<T>, (arg: T) => void] => {
	const fetchLocalStorage = useCallback(() => {
		try {
			const item = window.localStorage.getItem(key);
			return item ? JSON.parse(item) : defaultValue;
		} catch (error) {
			cerr(`Error Fetching ${key}`);
			return defaultValue;
		}
	}, [defaultValue, key]);

	const [storedValue, setStoredValue] = useState(fetchLocalStorage());

	useEffect(() => {
		window.addEventListener(`${key}-storage-changed`, () =>
			setStoredValue(fetchLocalStorage()),
		);

		return () => {
			return window.removeEventListener(
				`${key}-storage-changed`,
				() => setStoredValue(fetchLocalStorage()),
				true,
			);
		};
	}, [fetchLocalStorage, key]);

	const setValue = (value: unknown) => {
		try {
			setStoredValue(value);
			window.localStorage.setItem(key, JSON.stringify(value));
			window.dispatchEvent(new Event(`${key}-storage-changed`));
		} catch (error) {
			cerr(`Error Fetching ${key}`);
		}
	};
	return [Object.freeze(storedValue), setValue];
};

export default useLocalStorage;
