/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from 'react';
import CartProductRow from '../components/CartProductRow';
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import NavInfo from '../components/NavInfo';
import { useGetEnviosQuery } from '../generated/graphql';
import '../styles/Cart.css';
import { ProductProps } from '../types';
import regroupProducts from '../utils/regroupProducts';
import useLocalStorage from '../utils/useLocalStorage';

const CartScreen: React.FC = () => {
	const [products] = useLocalStorage<Array<ProductProps>>('products', []);

	const [selectedShipping, setShipping] = useState<'Local' | 'Nacional'>(
		'Local',
	);

	const { data } = useGetEnviosQuery();

	const productstotalPrice = useMemo(
		() => products.reduce((acc, p) => acc + p.price, 0),
		[products],
	);

	const shippingValue = useMemo(() => {
		if (productstotalPrice > 200000) {
			return 0;
		}
		if (selectedShipping === 'Local') {
			if (
				data?.envios &&
				data.envios[0] &&
				data.envios[0].EnvioLocal &&
				!Number.isNaN(parseInt(data.envios[0].EnvioLocal, 10))
			) {
				return parseInt(data.envios[0].EnvioLocal, 10);
			}
		} else if (selectedShipping === 'Nacional')
			if (
				data?.envios &&
				data.envios[0] &&
				data.envios[0].EnvioNacional &&
				!Number.isNaN(parseInt(data.envios[0].EnvioNacional, 10))
			) {
				return parseInt(data.envios[0].EnvioNacional, 10);
			}

		return 0;
	}, [data, productstotalPrice, selectedShipping]);

	return (
		<>
			<Nav />
			<section className="">
				<div
					className="relative w-full text-center"
					style={{
						minHeight: '170px',
						backgroundSize: 'cover',
						backgroundPosition: 'center center',
						backgroundImage:
							'url("https://res.cloudinary.com/kurtcovayne4/image/upload/v1617494988/page-header-bg_s8p66k.jpg")',
					}}
				>
					<div className="absolute w-full top-1/4 px-3">
						<h1 className="text-5xl mb-3 mx-auto">Carrito</h1>
						<p className="text-xl text-main-orange mb-3">Tienda</p>
					</div>
				</div>
				<div className="lg:px-32 md:px-24 px-4">
					<NavInfo path={['Inicio', 'Shop', 'Tienda']} />
				</div>
				<span className="border-b w-full h-px border-gray-500" />
				<div className="w-full lg:px-24 md:px-14 px-2">
					<div className="flex flex-wrap overflow-hidden">
						<div className="w-full overflow-hidden lg:w-9/12 px-2">
							<table className="w-full text-left w-100 text-gray-700 mb-3 border-collapse table-auto">
								<thead className="md:table-header-group hidden md:border-b border-gray-200">
									<tr className="text-gray-400 text-sm font-extralight ">
										<th className="border-t-0 py-5">
											Producto
										</th>
										<th className="border-t-0 py-5">
											Precio
										</th>
										<th className="border-t-0 py-5">
											Cantidad
										</th>
										<th className="border-t-0 py-5">
											Total
										</th>
										{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
										<th />
									</tr>
								</thead>
								<tbody className="md:border-b border-gray-200">
									{products.length === 0 && (
										<tr className="md:table-row md:w-auto md:static block w-full relative ">
											<td className="md:w-5/12 py-8 md:table-cell md:text-left w-full block text-center hover:text-main-orange transition-all duration-150 md:pl-6">
												Todavia no tienes productos en
												el carrito
											</td>
										</tr>
									)}
									{regroupProducts(products).map((p) => (
										<CartProductRow
											key={`${p.id}|${p.selectedColor}`}
											id={p.id}
											categories={p.categories}
											name={p.name}
											selectedColor={p.selectedColor}
											image={p.image}
											price={p.price}
											quantity={p.quantity}
											SKU={p.SKU}
										/>
									))}
								</tbody>
							</table>
							<div className="flex">
								<button
									type="button"
									className="inline-flex items-center mb-2 justify-center px-1 py-2 md:w-3/12 w-full md:ml-auto md:mr-12 mx-auto focus:outline-none transition-all bg-transparent text-center text-sm text-black border border-gray-500 hover:text-main-orange hover:bg-gray-100"
									onClick={() => {
										window.dispatchEvent(
											new Event(
												`products-storage-changed`,
											),
										);
									}}
								>
									<span className="mr-2">
										Actualizar Carro
									</span>

									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="w-3 h-3"
										fill="currentColor"
										viewBox="0 0 512 512"
									>
										<path d="M479.971,32.18c-21.72,21.211-42.89,43-64.52,64.301c-1.05,1.23-2.26-0.16-3.09-0.85   c-24.511-23.98-54.58-42.281-87.221-52.84c-37.6-12.16-78.449-14.07-117.029-5.59c-68.67,14.67-128.811,64.059-156.44,128.609   c0.031,0.014,0.062,0.025,0.093,0.039c-2.3,4.537-3.605,9.666-3.605,15.1c0,18.475,14.977,33.451,33.451,33.451   c15.831,0,29.084-11.002,32.555-25.773c19.757-41.979,58.832-74.445,103.967-85.527c52.2-13.17,111.37,1.33,149.4,40.041   c-22.03,21.83-44.391,43.34-66.33,65.26c59.52-0.32,119.06-0.141,178.59-0.09C480.291,149.611,479.931,90.891,479.971,32.18z" />
										<path d="M431.609,297.5c-14.62,0-27.041,9.383-31.591,22.453c-0.009-0.004-0.019-0.008-0.027-0.012   c-19.11,42.59-57.57,76.219-102.84,88.18c-52.799,14.311-113.45,0.299-152.179-39.051c21.92-21.76,44.369-43.01,66.189-64.869   c-59.7,0.049-119.41,0.029-179.11,0.01c-0.14,58.6-0.159,117.189,0.011,175.789c21.92-21.91,43.75-43.91,65.79-65.699   c14.109,13.789,29.76,26.07,46.92,35.869c54.739,31.971,123.399,38.602,183.299,17.891   c57.477-19.297,106.073-63.178,131.212-118.318c3.645-5.357,5.776-11.824,5.776-18.793C465.06,312.477,450.083,297.5,431.609,297.5   z" />
									</svg>
								</button>
							</div>
						</div>
						<aside className="w-full overflow-hidden lg:w-3/12 pt-5">
							<div className="bg-gray-100 border border-dashed border-gray-300 rounded-sm px-7 py-6 text-gray-500">
								<h3 className="w-full border-b border-gray-400 pb-3.5 text-black">
									Total de compra
								</h3>
								<div className="flex py-3 border-b border-gray-300 mb-3.5">
									<div className="w-7/12">Subtotal</div>
									<div className="w-5/12 text-right">
										$
										{products.reduce(
											(acc, p) => acc + p.price,
											0,
										)}
										$
									</div>
								</div>
								<h4 className="w-full mb-3.5">Shipping:</h4>
								<div className="flex text-sm">
									<div className="w-7/12">
										<button
											type="button"
											onClick={() => setShipping('Local')}
											className={`inline-block w-4 h-4 p-1 rounded-full border ${
												selectedShipping === 'Local'
													? 'border-main-orange'
													: 'border-gray-500'
											} group relative focus:outline-none`}
										>
											<span
												className={`w-2 h-2 absolute rounded-full transform -translate-x-1/2 -translate-y-1/2 ${
													selectedShipping === 'Local'
														? 'bg-main-orange'
														: 'bg-transparent'
												}`}
											/>
											<span className="hidden">
												Seleccionar Metodo de Entrega
											</span>
										</button>
										<p className="inline-block transform ml-2 translate-y-1/4">
											Envío a medellín:
										</p>
									</div>
									<div className="w-5/12 text-right ">
										$
										{(data?.envios &&
											data.envios[0]?.EnvioLocal) ??
											'0.0'}
									</div>
								</div>

								<div className="flex text-sm mb-3.5">
									<div className="w-7/12">
										<button
											type="button"
											onClick={() =>
												setShipping('Nacional')
											}
											className={`inline-block w-4 h-4 p-1 rounded-full border ${
												selectedShipping === 'Nacional'
													? 'border-main-orange'
													: 'border-gray-500'
											} group relative focus:outline-none`}
										>
											<span
												className={`w-2 h-2 absolute rounded-full transform -translate-x-1/2 -translate-y-1/2 ${
													selectedShipping ===
													'Nacional'
														? 'bg-main-orange'
														: 'bg-transparent'
												}`}
											/>
											<span className="hidden">
												Seleccionar Metodo de Entrega
											</span>
										</button>
										<p className="inline-block transform ml-2 translate-y-1/4">
											Envío a ciudades principales:
										</p>
									</div>
									<div className="w-5/12 text-right">
										$
										{(data?.envios &&
											data.envios[0]?.EnvioNacional) ??
											'0.0'}
									</div>
								</div>
								<div className="flex py-3 text-main-orange mb-3.5">
									<div className="w-7/12">Total</div>
									<div className="w-5/12 text-right">
										${productstotalPrice + shippingValue}
									</div>
								</div>
								{products.length > 0 ? (
									<a
										href="/checkout"
										className="inline-flex items-center justify-center px-1 py-2 w-full transition-all bg-transparent text-center text-sm bg-red-500 text-white hover:bg-main-brown hover:text-yellow-300"
									>
										Proceder al pago
									</a>
								) : (
									<span>
										Debes añadir productos al carrito
										primero
									</span>
								)}
							</div>
							<a
								href="/"
								type="button"
								className="inline-flex items-center justify-center px-1 py-2 w-full focus:outline-none transition-all text-center text-sm text-black border border-gray-300 hover:text-main-orange hover:bg-gray-100 mt-6 mb-16"
							>
								<span className="mr-2">
									Continuar comprando
								</span>

								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="w-3 h-3"
									fill="currentColor"
									viewBox="0 0 512 512"
								>
									<path d="M479.971,32.18c-21.72,21.211-42.89,43-64.52,64.301c-1.05,1.23-2.26-0.16-3.09-0.85   c-24.511-23.98-54.58-42.281-87.221-52.84c-37.6-12.16-78.449-14.07-117.029-5.59c-68.67,14.67-128.811,64.059-156.44,128.609   c0.031,0.014,0.062,0.025,0.093,0.039c-2.3,4.537-3.605,9.666-3.605,15.1c0,18.475,14.977,33.451,33.451,33.451   c15.831,0,29.084-11.002,32.555-25.773c19.757-41.979,58.832-74.445,103.967-85.527c52.2-13.17,111.37,1.33,149.4,40.041   c-22.03,21.83-44.391,43.34-66.33,65.26c59.52-0.32,119.06-0.141,178.59-0.09C480.291,149.611,479.931,90.891,479.971,32.18z" />
									<path d="M431.609,297.5c-14.62,0-27.041,9.383-31.591,22.453c-0.009-0.004-0.019-0.008-0.027-0.012   c-19.11,42.59-57.57,76.219-102.84,88.18c-52.799,14.311-113.45,0.299-152.179-39.051c21.92-21.76,44.369-43.01,66.189-64.869   c-59.7,0.049-119.41,0.029-179.11,0.01c-0.14,58.6-0.159,117.189,0.011,175.789c21.92-21.91,43.75-43.91,65.79-65.699   c14.109,13.789,29.76,26.07,46.92,35.869c54.739,31.971,123.399,38.602,183.299,17.891   c57.477-19.297,106.073-63.178,131.212-118.318c3.645-5.357,5.776-11.824,5.776-18.793C465.06,312.477,450.083,297.5,431.609,297.5   z" />
								</svg>
							</a>
						</aside>
					</div>
				</div>
			</section>

			<Footer />
		</>
	);
};

export default CartScreen;
