import React from 'react';
import '../styles/Carousel.css';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper-bundle.min.css';
import 'swiper/components/navigation/navigation.min.css';

// Import Swiper styles
type CarouselItem = {
	id: string;
	img_url: string;
	botonTitle: string;
	botonUrl: string;
};

type CarouselProps = {
	items: Partial<CarouselItem>[];
};

const Carousel: React.FC<CarouselProps> = ({ items }: CarouselProps) => {
	return (
		<>
			<button
				type="button"
				className="prevElCaret carousel-caret left-16 -translate-x-full select-none"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="currentColor"
					viewBox="0 0 16 16"
					role="img"
					className="inline-block w-4 h-4"
				>
					<path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
				</svg>
			</button>
			<button
				type="button"
				className="nextElCaret carousel-caret right-16 translate-x-full select-none"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="currentColor"
					viewBox="0 0 16 16"
					role="img"
					className="inline-block w-4 h-4"
				>
					<path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
				</svg>
			</button>
			<Swiper
				slidesPerView={1}
				navigation={{
					prevEl: '.prevElCaret',
					nextEl: '.nextElCaret',
				}}
				// autoplay
			>
				{items.map((item) => (
					<SwiperSlide key={item.id}>
						<div
							className="relative"
							style={{ height: '71vh' }}
							key={item.id}
						>
							<div className="text-center absolute top-3/4 transform -translate-y-1/2 w-full">
								{/* <h3
									className="text-white text-6xl font-bold uppercase select-none"
									style={{ letterSpacing: '18px' }}
									role="presentation"
								>
									{item.subtitle}
								</h3>
								<h2
									className="text-white font-bold uppercase select-none "
									style={{
										lineHeight: '83px',
										letterSpacing: '18px',
										fontSize: '90px',
									}}
									role="presentation"
								>
									{item.title}
								</h2> */}
								<a href={item.botonUrl ?? '/tienda'}>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="group w-80 h-40 mx-auto my-3 text-red-600 hover:text-main-gray transition-all duration-200 bg-transparent"
										fill="currentColor"
										viewBox="0 0 744 183"
									>
										<path
											d="M625.73,596.76c2.69-12.72,5.47-25,7.85-37.39,1.41-7.28,1.25-14.64-1.87-21.58a25.61,25.61,0,0,0-11.64-12.55,100.65,100.65,0,0,1-16.31-10.52c-6.33-5.19-5.8-10.1.68-15.16.39-.31.81-.59,1.22-.88,6.8-4.75,6.8-4.75,5.89-12.48,15.64-.69,31.24-1.63,46.85-2.05q111.95-3,223.89-5.8c30.32-.77,60.63-1.6,91-2.08q106-1.68,212-2.93c15.29-.16,30.62,1,45.9,1.92,5.31.34,11.21.63,13.48,7.4.28.85,2.76,1.35,4.23,1.38,8.5.12,17-.09,25.5.14a31.47,31.47,0,0,1,9.73,1.73c4.62,1.67,5.35,6.24,2.35,10.14a24.57,24.57,0,0,0-3.61,6.78c-1.86,5.36-5.72,8.06-11,8.86-4.41.67-8.88,1-13.44,2.69,5.62.56,11.26,1,16.86,1.72,6.74.87,13.65,1.31,20.15,3.15,10.54,3,15.71,10.51,14.8,19.71a18.52,18.52,0,0,1-18.27,16.73c-17.28.58-34.6-.15-51.9-.37-3.17,0-6.33-.47-9.49-.34-1.58.06-3.12,1-4.68,1.48,0,.61.06,1.22.1,1.83,5.28.6,10.57,1.17,15.85,1.82,7.75.94,15.62,1.4,23.22,3,9.76,2.11,15.22,9.26,15,17.94A17.55,17.55,0,0,1,1264,597.67c-9.86,1.13-19.92.78-29.89.78-14.16,0-28.32-.29-42.49-.34-2.74,0-4.33-.52-5.55-3.5-3-7.45-9.56-10.12-17-9.69-13.29.77-26.55,2.15-39.83,3.24-18.91,1.55-37.81,3.56-56.75,4.51-83.09,4.17-166.27,3.25-249.42,3.58q-96.5.39-193,.51Z"
											transform="translate(-588 -448)"
										/>
										<text
											xmlns="http://www.w3.org/2000/svg"
											className="text-main-orange group-hover:text-white transition-all duration-200"
											fill="currentColor"
											fontWeight="1000"
											fontSize="3.5rem"
										>
											<tspan x="120" y="100">
												{item.botonTitle}
											</tspan>
										</text>
									</svg>
								</a>
							</div>
							<div
								className="flex items-center w-full h-full"
								style={{
									backgroundSize: 'cover',
									backgroundPosition: 'center center',
									backgroundImage: `url(${
										item.img_url ??
										'https://tienda-shop-imagenes.s3.amazonaws.com/slide_2_bc45c0524b.jpg'
									})`,
								}}
							/>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</>
	);
};
export default Carousel;
