/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import NavInfo from '../components/NavInfo';
import {
	useAddPurchaseMutation,
	useGetDiscountQuery,
	useGetEnviosQuery,
} from '../generated/graphql';
import '../styles/Checkout.css';
import { ProductProps } from '../types';
import httpCall from '../utils/http_call';
import useLocalStorage from '../utils/useLocalStorage';

type FormValues = {
	names: string;
	lastNames: string;
	addressName: string;
	addressDetails: string;
	city: string;
	neighbourhood: string;
	phone: string;
	email: string;
};

const CheckoutScreen: React.FC = () => {
	const [products] = useLocalStorage<Array<ProductProps>>('products', []);

	const [selectedShipping, setShipping] = useState<'Local' | 'Nacional'>(
		'Local',
	);

	const [purchaseId, setPurchaseId] = useState<string>();
	const [signature, setSignature] = useState<string>();

	const [AddPurchase, { loading }] = useAddPurchaseMutation();

	const formRef = useRef<HTMLFormElement | null>(null);
	const [discountCode, setDiscountCode] = useState('');
	const [buyerEmail, setEmail] = useState('');
	const [fullName, setFullName] = useState('');

	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
	} = useForm<FormValues>();

	const { data } = useGetEnviosQuery();

	const { data: discountData } = useGetDiscountQuery({
		variables: { code: discountCode },
	});

	const productsTotalPrice = useMemo(() => {
		let accumulated = products.reduce((acc, p) => acc + p.price, 0);
		if (discountData?.codigos && discountData.codigos[0]) {
			accumulated -=
				accumulated *
				((discountData.codigos[0].ValorDescuento ?? 0) / 100);
		}
		return accumulated;
	}, [discountData, products]);

	const shippingValue = useMemo(() => {
		if (productsTotalPrice > 200000) {
			return 0;
		}
		if (selectedShipping === 'Local') {
			if (
				data?.envios &&
				data.envios[0] &&
				data.envios[0].EnvioLocal &&
				!Number.isNaN(parseInt(data.envios[0].EnvioLocal, 10))
			) {
				return parseInt(data.envios[0].EnvioLocal, 10);
			}
		} else if (selectedShipping === 'Nacional')
			if (
				data?.envios &&
				data.envios[0] &&
				data.envios[0].EnvioNacional &&
				!Number.isNaN(parseInt(data.envios[0].EnvioNacional, 10))
			) {
				return parseInt(data.envios[0].EnvioNacional, 10);
			}

		return 0;
	}, [data, productsTotalPrice, selectedShipping]);

	const onSubmit: SubmitHandler<FormValues> = async (formData, e) => {
		const {
			names,
			lastNames,
			addressName,
			addressDetails,
			city,
			neighbourhood,
			phone,
			email,
		} = formData;

		e?.preventDefault();
		const result = await AddPurchase({
			variables: {
				input: {
					data: {
						Nombres: names,
						Apellidos: lastNames,
						NombreCalle: addressName,
						NombreApartamento: addressDetails,
						NombreCiudad: city,
						NombreBarrio: neighbourhood,
						NumeroTelefono: phone,
						Correo: email,
						productos: products.map((p) => p.id),
					},
				},
			},
			context: {
				clientName: 'Compras',
			},
		});
		if (result.data?.createCompra?.compra?.id) {
			const signatureResult = await httpCall<
				{
					signature: string | null;
				},
				{
					id: string;
					value: number;
				}
			>(
				`${
					process.env.REACT_APP_HOOKS_ENDPOINT ??
					'http://localhost:3001'
				}/signature`,
				'POST',
				{
					id: result.data?.createCompra?.compra?.id,
					value: productsTotalPrice + shippingValue,
				},
			);
			if (signatureResult?.signature) {
				setEmail(email);
				setFullName(`${names} ${lastNames}`);
				setPurchaseId(result.data?.createCompra?.compra?.id);
				setSignature(signatureResult?.signature);
				if (formRef.current) {
					formRef.current.submit();
				}
			}
		} else {
			// eslint-disable-next-line no-alert
			alert('Ha ocurrido un error intentado realizar la compra');
		}
	};

	const [termsAccepted, setTermsAccepted] = useLocalStorage<boolean>(
		'termsAccepted',
		false,
	);

	return (
		<>
			<Nav />
			<form
				method="POST"
				ref={formRef}
				onSubmit={handleSubmit(onSubmit)}
				action={
					process.env.REACT_APP_PAYU_ENDPOINT ??
					'https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu'
				}
			>
				<div
					className="relative w-full text-center"
					style={{
						minHeight: '170px',
						backgroundSize: 'cover',
						backgroundPosition: 'center center',
						backgroundImage:
							'url("https://res.cloudinary.com/kurtcovayne4/image/upload/v1617494988/page-header-bg_s8p66k.jpg")',
					}}
				>
					<div className="absolute w-full top-1/4 px-3">
						<h1 className="text-5xl mb-3 mx-auto">Checkout</h1>
						<p className="text-xl text-main-orange mb-3">Tienda</p>
					</div>
				</div>
				<NavInfo path={['Inicio', 'Shop', 'Checkout']} />
				<span className="block border-b w-full h-px border-gray-200 mb-10" />
				<div className="w-full lg:px-24 md:px-14 px-2">
					<div className="flex flex-wrap overflow-hidden">
						<div className="w-full overflow-hidden lg:w-9/12 px-2">
							<input
								type="text"
								className="checkout-input border-dashed md:w-1/3 w-full"
								placeholder="Código de Descuento"
								value={discountCode}
								onChange={(e) =>
									setDiscountCode(e.target.value)
								}
							/>
							<h1 className="text-lg mb-2">
								Detalles de Facturación
							</h1>

							<div className="flex flex-wrap overflow-hidden">
								<label className="md:w-1/2 w-full px-2">
									<span className="text-sm text-gray-500">
										Nombres*
									</span>
									{errors.names?.type === 'required' && (
										<p className="text-red-500 text-sm">
											Este campo es requerido
										</p>
									)}
									<input
										type="text"
										className="checkout-input w-full"
										{...register('names', {
											required: true,
											maxLength: 20,
										})}
									/>
								</label>
								<label className="md:w-1/2 w-full">
									<span className="text-sm text-gray-500">
										Apellidos*
									</span>
									{errors.lastNames?.type === 'required' && (
										<p className="text-red-500 text-sm">
											Este campo es requerido
										</p>
									)}
									<input
										type="text"
										className="checkout-input w-full"
										{...register('lastNames', {
											required: true,
											maxLength: 20,
										})}
									/>
								</label>
								<label className="w-full pl-2">
									<span className="text-sm text-gray-500">
										Dirección detallada de envio de
										producto*
									</span>
									{errors.addressName?.type ===
										'required' && (
										<p className="text-red-500 text-sm">
											Este campo es requerido
										</p>
									)}
									<input
										type="text"
										placeholder="Nombre de la calle y numero"
										className="checkout-input w-full"
										{...register('addressName', {
											required: true,
											maxLength: 64,
										})}
									/>
								</label>
								<label className="w-full pl-2">
									{errors.addressDetails?.type ===
										'required' && (
										<p className="text-red-500 text-sm">
											Este campo es requerido
										</p>
									)}
									<input
										type="text"
										placeholder="Notas del pedido"
										className="checkout-input w-full"
										{...register('addressDetails', {
											maxLength: 64,
										})}
									/>
								</label>
								<label className="md:w-1/2 w-full px-2">
									<span className="text-sm text-gray-500">
										Departamento / Ciudad*
									</span>
									{errors.city?.type === 'required' && (
										<p className="text-red-500 text-sm">
											Este campo es requerido
										</p>
									)}
									<input
										type="text"
										className="checkout-input w-full"
										{...register('city', {
											required: true,
											maxLength: 20,
										})}
									/>
								</label>
								<label className="md:w-1/2 w-full">
									<span className="text-sm text-gray-500">
										Barrio*
									</span>
									{errors.neighbourhood?.type ===
										'required' && (
										<p className="text-red-500 text-sm">
											Este campo es requerido
										</p>
									)}
									<input
										type="text"
										className="checkout-input w-full"
										{...register('neighbourhood', {
											required: true,
											maxLength: 20,
										})}
									/>
								</label>
								<label className="md:w-1/2 ml-2 w-full">
									<span className="text-sm text-gray-500">
										Teléfono o Celular*
									</span>
									{errors.phone?.type === 'required' && (
										<p className="text-red-500 text-sm">
											Este campo es requerido
										</p>
									)}
									<input
										type="text"
										className="checkout-input w-full"
										{...register('phone', {
											required: true,
											minLength: 10,
										})}
									/>
								</label>
								<label className="w-full pl-2">
									<span className="text-sm text-gray-500">
										Correo Electronico*
									</span>
									{errors.email?.type === 'required' && (
										<p className="text-red-500 text-sm">
											Este campo es requerido
										</p>
									)}
									<input
										type="email"
										className="checkout-input w-full"
										{...register('email', {
											required: true,
											minLength: 5,
											maxLength: 128,
										})}
									/>
								</label>
								<div className="mb-4">
									<span className="text-base text-gray-500 block">
										Aceptar{' '}
										<a
											href="/terminos"
											className="text-red-400 underline"
										>
											Terminos y condiciones
										</a>
										:
									</span>

									<label className="pl-2 inline-block">
										<span className="text-lg text-gray-500">
											Si:
										</span>
										<input
											type="radio"
											className="inline-block mx-1 w-4 h-4 transform translate-y-0.5"
											checked={termsAccepted}
											readOnly
											onClick={() =>
												setTermsAccepted(true)
											}
										/>
									</label>
									<label className="pl-2 inline-block">
										<span className="text-lg text-gray-500">
											No:
										</span>
										<input
											type="radio"
											className="inline-block mx-1 w-4 h-4  transform translate-y-0.5"
											checked={!termsAccepted}
											readOnly
											onClick={() =>
												setTermsAccepted(false)
											}
										/>
									</label>
								</div>
								<input
									name="merchantId"
									type="hidden"
									value={
										process.env.REACT_APP_MERCHANT_ID ??
										'508029'
									}
								/>
								<input
									name="accountId"
									type="hidden"
									value={
										process.env.REACT_APP_ACCOUNT_ID ??
										'926796'
									}
								/>
								<input
									name="description"
									type="hidden"
									value={
										process.env
											.REACT_APP_PAYMENT_DESCRIPTION ??
										'Test PayU'
									}
								/>
								<input
									name="referenceCode"
									type="hidden"
									value={
										purchaseId
											? `eros-compra-${purchaseId}`
											: 'eros-compra-500'
									}
								/>
								<input
									name="amount"
									type="hidden"
									value={productsTotalPrice + shippingValue}
								/>
								<input name="tax" type="hidden" value="0" />
								<input
									name="taxReturnBase"
									type="hidden"
									value="0"
								/>
								<input
									name="buyerEmail"
									type="hidden"
									value={buyerEmail}
								/>
								<input
									name="buyerFullName"
									type="hidden"
									value={fullName}
								/>
								<input
									name="currency"
									type="hidden"
									value="COP"
								/>
								<input
									name="signature"
									type="hidden"
									value={signature}
								/>
								<input
									name="test"
									type="hidden"
									value={
										process.env.REACT_APP_PAYU_TESTING ??
										'1'
									}
								/>
								<input
									name="extra1"
									type="hidden"
									value={JSON.stringify({
										shippingValue,
										shippingData: `${getValues(
											'addressName',
										)}, ${getValues(
											'addressDetails',
										)}`.slice(-180),
									})}
								/>
								<input
									name="shippingAddress"
									type="hidden"
									value={`${getValues(
										'neighbourhood',
									)} ${getValues('addressDetails')}`}
								/>
								<input
									name="shippingCity"
									type="hidden"
									value={`${getValues('city')}`}
								/>
								<input
									name="shippingCountry"
									type="hidden"
									value="Colombia"
								/>
								<input
									name="confirmationUrl"
									type="hidden"
									value={`${process.env.REACT_APP_HOOKS_ENDPOINT}/confirmation`}
								/>
								<input
									name="telephone"
									type="hidden"
									value={`${getValues('phone')}`}
								/>
							</div>
						</div>
						<aside className="w-full overflow-hidden lg:w-3/12 pt-5 mb-6">
							<div className="bg-gray-100 border border-dashed border-gray-300 rounded-sm px-7 py-6 text-gray-500">
								<h3 className="w-full border-b border-gray-400 pb-3.5 text-black">
									Total de compra
								</h3>
								<div className="flex py-3 border-b border-gray-300 mb-3.5">
									<div className="w-7/12">Subtotal</div>
									<div className="w-5/12 text-right">
										${productsTotalPrice}$
									</div>
								</div>
								<h4 className="w-full mb-3.5">Shipping:</h4>
								<div className="flex text-sm">
									<div className="w-7/12">
										<button
											type="button"
											onClick={() => setShipping('Local')}
											className={`inline-block w-4 h-4 p-1 rounded-full border ${
												selectedShipping === 'Local'
													? 'border-main-orange'
													: 'border-gray-500'
											} group relative focus:outline-none`}
										>
											<span
												className={`w-2 h-2 absolute rounded-full transform -translate-x-1/2 -translate-y-1/2 ${
													selectedShipping === 'Local'
														? 'bg-main-orange'
														: 'bg-transparent'
												}`}
											/>
											<span className="hidden">
												Seleccionar Metodo de Entrega
											</span>
										</button>
										<p className="inline-block transform ml-2 translate-y-1/4">
											Envío a medellín:
										</p>
									</div>
									<div className="w-5/12 text-right ">
										$
										{(data?.envios &&
											data.envios[0]?.EnvioLocal) ??
											'0.0'}
									</div>
								</div>

								<div className="flex text-sm mb-3.5">
									<div className="w-7/12">
										<button
											type="button"
											onClick={() =>
												setShipping('Nacional')
											}
											className={`inline-block w-4 h-4 p-1 rounded-full border ${
												selectedShipping === 'Nacional'
													? 'border-main-orange'
													: 'border-gray-500'
											} group relative focus:outline-none`}
										>
											<span
												className={`w-2 h-2 absolute rounded-full transform -translate-x-1/2 -translate-y-1/2 ${
													selectedShipping ===
													'Nacional'
														? 'bg-main-orange'
														: 'bg-transparent'
												}`}
											/>
											<span className="hidden">
												Seleccionar Metodo de Entrega
											</span>
										</button>
										<p className="inline-block transform ml-2 translate-y-1/4">
											Envío ciudades principales:
										</p>
									</div>
									<div className="w-5/12 text-right">
										$
										{(data?.envios &&
											data.envios[0]?.EnvioNacional) ??
											'0.0'}
									</div>
								</div>
								<div className="flex py-3 text-main-orange mb-3.5">
									<div className="w-7/12">Total</div>
									<div className="w-5/12 text-right">
										${productsTotalPrice + shippingValue}
									</div>
								</div>
								{products.length > 0 && termsAccepted && (
									<button
										type="submit"
										disabled={loading}
										className="inline-flex items-center justify-center px-1 py-2 w-full transition-all bg-transparent text-center text-sm bg-red-500 text-white hover:bg-main-brown hover:text-yellow-300"
									>
										Proceder al pago
									</button>
								)}
								{products.length === 0 && (
									<span>
										Debes añadir productos al carrito
										primero
									</span>
								)}
								{!termsAccepted && (
									<span>
										Debes aceptar los terminos y condiciones
										para poder comprar.
									</span>
								)}
							</div>
						</aside>
					</div>
				</div>
			</form>

			<Footer />
		</>
	);
};

export default CheckoutScreen;
