const resToJson = async (res: Response) => {
	const contentType = res.headers.get('content-type');
	if (contentType && contentType.indexOf('application/json') !== -1) {
		return res.json();
	}
	return null;
};
const httpCall = async <T, B>(
	url: string,
	method = 'get',
	body: B,
	headers = {},
	controller = new AbortController(),
): Promise<T | null> => {
	try {
		const options: RequestInit = {
			method: method.toUpperCase(),
			signal: controller.signal,
			headers: {
				...headers,
				'Content-Type': 'application/json',
			},
		};
		if (typeof body === 'object' && !(Object.keys(body).length === 0)) {
			options.body = JSON.stringify(body);
		}
		const timeOutCheck = setTimeout(() => controller.abort(), 15 * 1000);
		const res = await fetch(url, options);
		clearTimeout(timeOutCheck);
		const json = await resToJson(res);
		return json;
	} catch (err) {
		return null;
	} finally {
		controller.abort();
	}
};

export default httpCall;
