import React, { useState } from 'react';
import { useNotifications } from '../services/Notifications';
import { ProductProps, ProductsWithQuantity } from '../types';
import useLocalStorage from '../utils/useLocalStorage';

const CartProductRow: React.FC<ProductsWithQuantity> = ({
	name,
	price,
	categories,
	quantity,
	selectedColor,
	id,
	image,
	topText,
	SKU,
}: ProductsWithQuantity) => {
	const [products, setProducts] = useLocalStorage<Array<ProductProps>>(
		'products',
		[],
	);
	const [, setQuantityValue] = useState(quantity);
	const [, addNotification] = useNotifications();

	return (
		<tr className="md:table-row md:w-auto md:static md:bg-transparent bg-white md:shadow-none shadow-md md:my-0 my-2 block w-full relative ">
			<td className="md:w-5/12 py-8 md:table-cell md:text-left w-full block text-center hover:text-main-orange transition-all duration-150 md:pl-6">
				<a
					href={`/producto/${SKU}`}
					className="flex items-center md:justify-start justify-center"
				>
					<img
						src={image}
						alt={name}
						className="md:w-24 md:inline-block w-36 block mr-6 rounded-md"
					/>
					<div className="md:inline-block block">
						<span className="block text-lg">{name}</span>
						<span className="font-bold">Color: </span>
						<span>{selectedColor ?? 'No tiene'}</span>
					</div>
				</a>
			</td>
			<td className="md:w-2/12 md:table-cell md:text-left w-full block text-center">
				${price}
			</td>
			<td className="md:w-28 md:table-cell md:text-left w-full block text-center px-3">
				<div className="group relative flex items-stretch w-24 h-10 border md:mx-0 mx-auto">
					<button
						type="button"
						className="absolute left-0 top-0 -mr-px flex border-none py-2.5 px-2 focus:outline-none"
						onClick={() => {
							let deleted = false;
							const newProducts = [...products].filter((p) => {
								if (deleted) return true;
								const res =
									p.id === id &&
									p.selectedColor === selectedColor;
								deleted = res;
								return !res;
							});

							setProducts(newProducts);

							// setQuantityValue(quantityValue - 1);
						}}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="currentColor"
							className="w-4 h-4 hover:text-main-orange"
							viewBox="0 0 16 16"
						>
							<path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
						</svg>
					</button>
					<input
						type="number"
						onFocus={(e) => {
							e.currentTarget.parentElement?.children[0].classList.add(
								'hidden',
							);
							e.currentTarget.parentElement?.children[2].classList.add(
								'hidden',
							);
						}}
						onBlur={(e) => {
							e.currentTarget.parentElement?.children[0].classList.remove(
								'hidden',
							);
							e.currentTarget.parentElement?.children[2].classList.remove(
								'hidden',
							);
						}}
						value={quantity}
						readOnly
						className="no-arrow-input text-center w-full border border-gray-400 focus:border-main-orange focus:outline-none"
					/>
					<button
						type="button"
						className="absolute right-0 top-0 -mr-px flex border-none py-2.5 px-2 focus:outline-none"
						onClick={() => {
							setProducts([
								...products,
								{
									categories,
									id,
									image,
									name,
									price,
									topText,
									selectedColor,
									SKU,
								},
							]);
							setQuantityValue((q) => q + 1);
						}}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="currentColor"
							className="w-4 h-4 hover:text-main-orange"
							viewBox="0 0 16 16"
						>
							<path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
						</svg>
					</button>
				</div>
			</td>
			<td className="md:w-2/12 md:table-cell md:text-left w-full block text-center text-main-orange">
				${price * quantity}
			</td>
			<td className="md:w-1/12 md:table-cell md:text-left w-full block text-right md:items-end">
				<div className="w-full flex justify-center">
					<button
						type="button"
						className="md:bg-transparent bg-red-500 justify-center p-1 rounded-md m-1"
						onClick={() => {
							addNotification({
								alert_type: 'success',
								autohide: true,
								delay: 3,
								text: `Se quito el producto ${name} del carrito.`,
								title: 'Carrito 🛒',
							});
							setProducts(
								[...products].filter(
									(p) =>
										!(
											p.id === id &&
											p.selectedColor === selectedColor
										),
								),
							);
						}}
					>
						<span className="md:hidden inline-block text-white">
							Borrar
						</span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="inline-block w-6 h-6 md:text-gray-400 text-white hover:text-black transition-all duration-500"
							fill="currentColor"
							viewBox="0 0 16 16"
						>
							<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
						</svg>
					</button>
				</div>
			</td>
		</tr>
	);
};

export default CartProductRow;
