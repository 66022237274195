import React, { useState } from 'react';
import { RegularPopupFragment } from '../generated/graphql';
import useLocalStorage from '../utils/useLocalStorage';

type PopupProps = RegularPopupFragment & {
	hide: () => void;
};

const Popup: React.FC<PopupProps> = ({
	hide,
	Habilitado,
	Imagen,
	PorcentajeDescuento,
	Texto,
	TextoBoton,
}: PopupProps) => {
	const [closed, setClosed] = useLocalStorage<boolean>('popupClosed', false);
	const [copied, setCopied] = useState(false);

	if (Habilitado && !closed) {
		return (
			<div
				className="flex fixed top-0 left-0 h-screen w-screen text-sm transform transition-all duration-300 z-50 pointer-events-auto"
				style={{
					backgroundColor: 'rgba(51, 51, 51, .5)',
					backdropFilter: 'blur(2px)',
				}}
			>
				<div className="absolute flex left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white lg:w-3/5 md:w-11/12 w-full">
					<div className="flex items-center justify-center w-2/3">
						<div className="flex flex-col text-center">
							<h2 className="md:text-5xl text-2xl my-2 text-black uppercase">
								<span className="text-main-orange">
									{PorcentajeDescuento}%
								</span>{' '}
								gratis
							</h2>
							<span className="md:text-4xl text-xl my-2 opacity-80">
								{Texto}
							</span>
							<div className="flex flex-row w-1/2 my-2 mx-auto">
								<>
									<button
										type="button"
										className="bg-gray-900 text-white w-36 h-12 rounded-lg transition-all delay-500 opacity-100"
										onClick={async () => {
											setCopied(true);
											navigator.permissions
												.query({
													name: 'clipboard-write',
												})
												.then(async (result) => {
													if (
														result.state ===
															'granted' ||
														result.state ===
															'prompt'
													) {
														if (Texto) {
															await navigator.clipboard.writeText(
																Texto,
															);
														}
													}
												});
										}}
									>
										{copied ? 'Copiado!' : TextoBoton}
									</button>
								</>
							</div>
						</div>
					</div>
					<div className="w-1/3 flex justify-end">
						<img
							src={
								(Imagen && Imagen[0] && Imagen[0].url) ??
								'https://d-themes.com/react/molla/demo-6/assets/images/popup/newsletter/img-1.jpg'
							}
							alt="newsletter"
							className="max-w-full max-h-full"
						/>
					</div>
					<button
						type="button"
						className="flex absolute right-4 top-4 bg-white rounded-full items-center justify-center p-2"
						onClick={() => {
							setClosed(true);
							hide();
						}}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="w-3 h-3 text-black"
							fill="currentColor"
							viewBox="0 0 16 16"
						>
							<path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
						</svg>
					</button>
				</div>
			</div>
		);
	}
	return null;
};
export default Popup;
