/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import NavInfo from '../components/NavInfo';

const NotFoundScreen: React.FC = () => {
	return (
		<>
			<Nav />
			<div className="lg:px-32 md:px-24 px-4">
				<NavInfo path={['Inicio', 'Paginas', '404']} />
			</div>
			<section className="flex bg-white text-center text-gray-700">
				<div
					className="relative w-full"
					style={{
						minHeight: '540px',
						backgroundSize: 'cover',
						backgroundPosition: 'center center',
						backgroundImage:
							'url("https://res.cloudinary.com/kurtcovayne4/image/upload/v1617492522/error-bg_bwqduz.jpg")',
					}}
				>
					<div className="absolute w-full top-1/4 px-3">
						<h1 className="text-5xl mb-5 mx-auto">Error 404</h1>
						<p className="text-sm text-gray-500 mb-3">
							Lo lamentamos, pero la pagina que buscas no está
							disponible
						</p>
						<a
							href="/"
							className="inline-flex items-center justify-center px-1 py-2 w-52 transition-all bg-white text-main-orange border border-main-orange hover:bg-main-orange hover:text-white"
						>
							Volver al inicio
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="currentColor"
								className="w-6 h-4"
								viewBox="0 0 16 16"
							>
								<path
									fillRule="evenodd"
									d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
								/>
							</svg>
						</a>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default NotFoundScreen;
