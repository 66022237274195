import { ProductProps, ProductsWithQuantity } from '../types';

const regroupProducts = (
	products: ReadonlyArray<ProductProps>,
): Array<ProductsWithQuantity> => {
	// eslint-disable-next-line prefer-const
	const res: Map<string, ProductsWithQuantity> = new Map([]);

	for (let i = 0; i < products.length; i += 1) {
		const product = products[i];
		const productIdentifier = `${product.id}|${product.selectedColor}`;
		const existingStackedProduct = res.get(productIdentifier);
		if (existingStackedProduct) {
			res.set(productIdentifier, {
				...existingStackedProduct,
				quantity: existingStackedProduct.quantity + 1,
			});
		} else {
			res.set(productIdentifier, { ...product, quantity: 1 });
		}
	}

	return [...res.values()].sort((a, b) => {
		if (a.id > b.id) return 1;
		if (a.id < b.id) return -1;
		if (a.id === b.id) {
			if (a.quantity < b.quantity) return 1;
			if (a.quantity > b.quantity) return -1;
		}
		return 0;
	});
};

export default regroupProducts;
