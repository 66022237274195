import React, { useEffect, useState } from 'react';
import { SideBySideMagnifier } from 'react-image-magnifiers';
import { useHistory, useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import marked from 'marked';
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import NavInfo from '../components/NavInfo';
import { useGetProductQuery } from '../generated/graphql';
import { useNotifications } from '../services/Notifications';
import '../styles/Product.css';
import { ProductProps } from '../types';
import useLocalStorage from '../utils/useLocalStorage';

const ProductScreen: React.FC = () => {
	const history = useHistory();
	const { name } = useParams<{ name: string }>();
	useEffect(() => {
		if (typeof name === 'undefined') {
			history.replace('/tienda');
		}
	}, [name, history]);

	const { data } = useGetProductQuery({ variables: { name } });

	const [selectedImageIdx, setSelectedImageIdx] = useState(0);

	const [cantidad, setCantidad] = useState(1);

	const [products, setProducts] = useLocalStorage<Array<ProductProps>>(
		'products',
		[],
	);
	const [, addNotification] = useNotifications();

	const [selectedColor, setSelectedColor] = useState<number | null>(null);

	return (
		<>
			<Nav />
			<section className="w-full lg:px-28 md:px-16 px-4 mb-20">
				<NavInfo
					path={[
						'Inicio',
						'Productos',
						(data?.productos &&
							data?.productos[0] &&
							data?.productos[0].Titulo) ??
							'Default',
					]}
				/>
				<div className="flex flex-wrap overflow-hidden">
					<div className="w-full overflow-hidden md:w-1/2 px-2">
						<div className="flex w-full">
							<div className="w-2/12 ">
								{data?.productos &&
									data?.productos[0] &&
									data?.productos[0].Imagenes?.map(
										(imagen, idx) => (
											<button
												key={imagen?.url}
												type="button"
												className="mw-full mb-4 cursor-pointer relative block focus:outline-none"
												onClick={() =>
													setSelectedImageIdx(idx)
												}
											>
												<img
													className="border border-main-orange"
													src={imagen?.url}
													alt={
														(data?.productos &&
															data
																?.productos[0] &&
															data
																?.productos[0] &&
															data.productos[0]
																.Titulo) ??
														'Alt'
													}
												/>
											</button>
										),
									)}
							</div>
							<div className="mx-auto pl-2">
								<div className="w-3/4 mx-auto">
									<SideBySideMagnifier
										imageSrc={
											(data?.productos &&
												data?.productos[0] &&
												data?.productos[0].Imagenes &&
												data.productos[0].Imagenes[
													selectedImageIdx
												] &&
												data.productos[0].Imagenes[
													selectedImageIdx
												]?.url) ??
											'https://res.cloudinary.com/kurtcovayne4/image/upload/c_crop,h_327,w_277/v1617839211/eros/banner-5_wj90dg.jpg'
										}
										imageAlt="Example"
										className="img-container"
										cursorStyle="zoom-in"
										alwaysInPlace
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="w-full overflow-hidden md:w-1/2 px-2">
						<h1 className="font-normal text-2xl">
							{data?.productos &&
								data?.productos[0] &&
								data?.productos[0].Titulo}
						</h1>
						<div className="text-2xl mb-5">
							{data?.productos &&
							data?.productos[0] &&
							data?.productos[0].salePrice ? (
								<>
									<span className="mr-4 text-red-500">
										$
										{data?.productos &&
											data?.productos[0] &&
											data?.productos[0].salePrice}
									</span>
									<span className="mr-4 text-gray-300 line-through">
										$
										{data?.productos &&
											data?.productos[0] &&
											data?.productos[0].precio}
									</span>
								</>
							) : (
								<span className="mr-4 text-red-500">
									$
									{data?.productos &&
										data?.productos[0] &&
										data?.productos[0].precio}
								</span>
							)}
						</div>
						<div
							className="mb-6 text-base text-gray-500 list-auto-styling"
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{
								__html: marked(
									(data?.productos &&
										data?.productos[0] &&
										data?.productos[0].Descripcion) ??
										'',
									{
										sanitize: true,
										sanitizer: DOMPurify.sanitize,
										smartLists: true,
										xhtml: true,
										silent: true,
										gfm: true,
									},
								),
							}}
						/>
						{data?.productos &&
							data?.productos[0] &&
							data?.productos[0].colores &&
							data?.productos[0].colores.length > 0 && (
								<div className="flex mb-8">
									<span className="inline-block w-16 text-lg">
										Color:
									</span>
									{data.productos[0].colores.map((c) => (
										<button
											type="button"
											className="inline-block w-5 h-5 mx-2 p-1 rounded-full group relative focus:outline-none transform translate-y-1/4"
											onClick={() =>
												setSelectedColor(
													parseInt(c?.id ?? '', 10) ??
														null,
												)
											}
											style={{
												backgroundColor:
													c?.CodigoColor ?? '#333',
											}}
										>
											<span
												className="hidden"
												aria-hidden
											>
												{c?.NombreColor}
											</span>
											{selectedColor ===
												parseInt(c?.id ?? '', 10) && (
												<svg
													xmlns="http://www.w3.org/2000/svg"
													className="w-4 h-4 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white"
													fill="currentColor"
													viewBox="0 0 16 16"
												>
													<path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
												</svg>
											)}
										</button>
									))}
								</div>
							)}
						<span className="text-red-500">
							Tallas: Los arneses se fabrican de forma que se
							puedan graduar para las tallas comunes, s, m, l, xl.
							En caso que un usuario requiera una talla extra
							grande o xs debe informar para hacerlo a medida
						</span>
						<div className="flex mb-8 mt-6">
							<span className="inline-block transform translate-y-1/4 mr-4">
								Cantidad:
							</span>
							<div className="group relative flex items-stretch w-32 h-10 border md:mx-0 mx-auto">
								<button
									type="button"
									className="absolute left-0 top-0 -mr-px flex border-none py-2.5 px-2 focus:outline-none"
									onClick={() => setCantidad((c) => c - 1)}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="currentColor"
										className="w-4 h-4 hover:text-main-orange"
										viewBox="0 0 16 16"
									>
										<path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
									</svg>
								</button>
								<input
									type="number"
									onFocus={(e) => {
										e.currentTarget.parentElement?.children[0].classList.add(
											'hidden',
										);
										e.currentTarget.parentElement?.children[2].classList.add(
											'hidden',
										);
									}}
									onBlur={(e) => {
										e.currentTarget.parentElement?.children[0].classList.remove(
											'hidden',
										);
										e.currentTarget.parentElement?.children[2].classList.remove(
											'hidden',
										);
									}}
									value={cantidad}
									onChange={(e) =>
										setCantidad(
											e.currentTarget.valueAsNumber,
										)
									}
									className="no-arrow-input text-center w-full border border-gray-400 focus:border-main-orange focus:outline-none"
								/>
								<button
									type="button"
									className="absolute right-0 top-0 -mr-px flex border-none py-2.5 px-2 focus:outline-none"
									onClick={() => setCantidad((c) => c + 1)}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="currentColor"
										className="w-4 h-4 hover:text-main-orange"
										viewBox="0 0 16 16"
									>
										<path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
									</svg>
								</button>
							</div>
						</div>
						<button
							type="button"
							onClick={() => {
								if (
									selectedColor === null &&
									data?.productos &&
									data.productos[0] &&
									data.productos[0].colores &&
									data.productos[0].colores.length > 0
								) {
									addNotification({
										alert_type: 'warning',
										autohide: true,
										delay: 3,
										text:
											'Debes seleccionar un color para añadir este producto al carrito',
										title: 'Carrito 🛒',
									});
									return;
								}
								if (data?.productos && data.productos[0]) {
									addNotification({
										alert_type: 'success',
										autohide: true,
										delay: 3,
										text: `Se ${
											cantidad > 1
												? `añadieron ${cantidad}`
												: 'añadio'
										} ${
											(selectedColor &&
												data.productos &&
												data.productos[0] &&
												data.productos[0].colores &&
												data.productos[0].colores.find(
													(c) =>
														parseInt(
															c?.id ?? '-1',
															10,
														) === selectedColor,
												)?.NombreColor) ??
											''
										} ${
											data?.productos &&
											data?.productos[0] &&
											data?.productos[0].Titulo
										} al carrito con exito`,
										title: 'Carrito 🛒',
									});
									setProducts([
										...products,
										...Array(cantidad).fill({
											id: data.productos[0].id,
											categories:
												data.productos[0].categoria
													?.Nombre ?? '',
											name:
												data.productos[0].Titulo ?? '',
											image:
												(data.productos[0].Imagenes &&
													data.productos[0].Imagenes[
														selectedImageIdx
													]?.url) ??
												'',
											price:
												data.productos[0].salePrice ??
												data.productos[0].precio ??
												0,
											topText:
												(data.productos[0].Descuento
													? 'Descuento'
													: undefined) ||
												(data.productos[0].Nuevo
													? 'Nuevo'
													: undefined),
											SKU: data.productos[0].SKU ?? '',
											selectedColor:
												(selectedColor &&
													data.productos &&
													data.productos[0] &&
													data.productos[0].colores &&
													data.productos[0].colores.find(
														(c) =>
															parseInt(
																c?.id ?? '-1',
																10,
															) === selectedColor,
													)?.NombreColor) ??
												null,
										} as ProductProps),
									]);
								}
							}}
							className="inline-flex items-center justify-center px-1 py-2 w-52 transition-all bg-white text-main-orange border border-main-orange hover:bg-main-orange hover:text-white"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="currentColor"
								className="w-4 h-4 mr-2 transform -translate-y-px"
								viewBox="0 0 16 16"
							>
								<path d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9V5.5z" />
								<path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
							</svg>
							Añadir al carrito
						</button>
						<span className="block my-6 text-sm text-gray-500">
							Categorias:{' '}
							{data?.productos &&
								data?.productos[0] &&
								data?.productos[0].categoria?.Nombre}
						</span>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default ProductScreen;
