import React, { useEffect, useRef, useState } from 'react';
import '../styles/Nav.css';
import Facebook from '../assets/Facebook.svg';
import Whatsapp from '../assets/Whatsapp.svg';
import Instagram from '../assets/Instagram.svg';
import Logo from '../assets/Logo.svg';
import { useGetNavQuery } from '../generated/graphql';
import useLocalStorage from '../utils/useLocalStorage';
import { ProductProps } from '../types';
import regroupProducts from '../utils/regroupProducts';
import { useNotifications } from '../services/Notifications';

type ActualScreen =
	| 'Inicio'
	| 'Tienda'
	| 'Somos'
	| 'Promos'
	| 'Blog'
	| 'Contacto';
type NavProps = {
	actualScreen?: ActualScreen;
};

const Nav: React.FC<NavProps> = ({ actualScreen }: NavProps) => {
	const navRef = useRef<HTMLDivElement | null>(null);
	const topRef = useRef<HTMLHeadElement | null>(null);
	const upButtonRef = useRef<HTMLButtonElement | null>(null);
	const sidebarRef = useRef<HTMLDivElement | null>(null);

	const { data } = useGetNavQuery();

	const [products, setProducts] = useLocalStorage<Array<ProductProps>>(
		'products',
		[],
	);

	const [, addNotification] = useNotifications();

	const [showWhatsappTray, setShowWhatsappTray] = useState(false);

	const cartInfoRef = useRef<HTMLDivElement | null>(null);
	const categoriesDropdownRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const scrollCb = () => {
			const { innerWidth: windowWidth } = window;
			if (navRef.current && upButtonRef.current) {
				if (
					windowWidth >= 768 &&
					window.scrollY >
						navRef.current.offsetTop + navRef.current.offsetHeight
				) {
					navRef.current.classList.add('fixed');
					navRef.current.classList.remove('-top-8');
					navRef.current.classList.add('top-0');

					upButtonRef.current.classList.remove('hidden');
				} else {
					navRef.current.classList.remove('fixed');
					navRef.current.classList.add('-top-8');
					navRef.current.classList.remove('top-0');

					upButtonRef.current.classList.add('hidden');
				}
			}
		};
		window.addEventListener('scroll', scrollCb);
		return () => window.removeEventListener('scroll', scrollCb);
	}, [navRef]);

	const [showSidebar, setShowSidebar] = useState(false);

	useEffect(() => {
		const handleClickOutside = (e: MouseEvent) => {
			if (
				sidebarRef.current &&
				!sidebarRef.current.contains(e.target as Node)
			) {
				setShowSidebar(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		// eslint-disable-next-line consistent-return
		return () =>
			document.removeEventListener('mousedown', handleClickOutside);
	}, [sidebarRef]);

	return (
		<header
			className="relative antialiased font-semibold font-sans z-30"
			ref={topRef}
		>
			<div className="top-navbar">
				<div className="md:flex border-b hidden">
					<a
						href={`https://wa.me/${(
							(data?.headers &&
								data.headers[0] &&
								data.headers[0].NumeroTelefono) ??
							'+57 350 453 8519'
						).replaceAll(
							/(\+| )/g,
							'',
						)}?text=Me%20gustar%C3%ADa%20saber%20el%20precio%20del%20producto`}
						rel="noreferrer"
						target="_blank"
						className="inline-block text-black font-bold text-base mt-1 hover:text-yellow-600"
					>
						<img
							src={Whatsapp}
							className="inline-block mr-0.5 rounded-md transform opacity-80 hover:opacity-100 hover:translate-y-1"
							style={{ width: '3.75rem', height: '3.75rem' }}
							alt="Whatsapp"
						/>
						<span className="font-extrabold text-lg tracking-wide">
							{(data?.headers &&
								data.headers[0] &&
								data.headers[0].NumeroTelefono) ??
								'+57 350 453 8519'}
						</span>
					</a>
					<div className="inline-block text-gray-700 ml-auto">
						<a
							href={
								(data?.headers &&
									data.headers[0] &&
									data.headers[0].Facebook) ??
								'https://facebook.com'
							}
							rel="noreferrer"
							target="_blank"
						>
							<img
								src={Facebook}
								className="inline-block w-16 h-16 rounded-md transform opacity-80 hover:opacity-100 hover:translate-y-1"
								alt="Facebook"
							/>
						</a>
						<a
							href={
								(data?.headers &&
									data.headers[0] &&
									data.headers[0].Instagram) ??
								'https://instagram.com'
							}
							rel="noreferrer"
							target="_blank"
						>
							<img
								src={Instagram}
								className="inline-block ml-1 mr-2 w-16 h-16 rounded-full transform opacity-80 hover:opacity-100 hover:translate-y-1"
								alt="Instagram"
							/>
						</a>
					</div>
				</div>
			</div>
			<div className="top-navbar">
				<div className="flex">
					<div className="inline-block w-5/12">
						<a href="/">
							<img
								src={
									(data?.headers &&
										data.headers[0] &&
										data.headers[0].logo?.url) ??
									Logo
								}
								className="md:my-2 w-52"
								alt="Logo"
							/>
						</a>
					</div>
					<div
						className="group relative inline-block text-gray-700 ml-auto my-auto mr-10"
						onMouseEnter={() => {
							if (cartInfoRef.current) {
								cartInfoRef.current.classList.remove(
									'pointer-events-none',
								);
								cartInfoRef.current.classList.add(
									'pointer-events-auto',
								);
							}
						}}
						onMouseLeave={() => {
							if (cartInfoRef.current) {
								cartInfoRef.current.classList.remove(
									'pointer-events-auto',
								);
								cartInfoRef.current.classList.add(
									'pointer-events-none',
								);
							}
						}}
					>
						<span className="relative inline-block transition-colors duration-100 group-hover:text-yellow-600">
							<svg
								className="inline-block mb-px mr-2 w-20 h-16 transition-colors duration-100 hover:text-main-orange"
								role="img"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 576 512"
							>
								<path
									fill="currentColor"
									d="M551.991 64H144.28l-8.726-44.608C133.35 8.128 123.478 0 112 0H12C5.373 0 0 5.373 0 12v24c0 6.627 5.373 12 12 12h80.24l69.594 355.701C150.796 415.201 144 430.802 144 448c0 35.346 28.654 64 64 64s64-28.654 64-64a63.681 63.681 0 0 0-8.583-32h145.167a63.681 63.681 0 0 0-8.583 32c0 35.346 28.654 64 64 64 35.346 0 64-28.654 64-64 0-18.136-7.556-34.496-19.676-46.142l1.035-4.757c3.254-14.96-8.142-29.101-23.452-29.101H203.76l-9.39-48h312.405c11.29 0 21.054-7.869 23.452-18.902l45.216-208C578.695 78.139 567.299 64 551.991 64zM208 472c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm256 0c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm23.438-200H184.98l-31.31-160h368.548l-34.78 160z"
								/>
							</svg>
							<span className="flex absolute h-10 w-10 top-0 left-3/4">
								<span className="relative inline-flex rounded-full h-6 w-6 bg-red-500 text-white">
									<span className="font-base ml-1.5">
										{products.length}
									</span>
								</span>
							</span>
						</span>
						<p className="text-base inline-block transition-colors duration-100 group-hover:text-yellow-600">
							{products.reduce((acc, p) => acc + p.price, 0)}$
						</p>
						<div
							className="cart-info pointer-events-none"
							ref={cartInfoRef}
						>
							{products.length === 0 ? (
								'No hay productos en el carro'
							) : (
								<>
									<div className="max-h-48 overflow-auto pb-1">
										{regroupProducts(products).map((p) => (
											<div
												className="flex justify-between border-b max-h-20"
												key={`${p.id}|${p.selectedColor}`}
											>
												<div className="w-1/2 inline-block text-left">
													<span className="text-sm">
														{p.name}{' '}
														{p.selectedColor ?? ''}
													</span>
													<br />
													<span className="text-xs">
														{p.quantity}x$
														{p.price}
													</span>
												</div>
												<div className="w-1/2 flex justify-end mb-1">
													<img
														className="inline-block max-w-full max-h-full"
														src={p.image}
														alt={p.name}
													/>
													<button
														type="button"
														className="focus:outline-none ml-1.5"
														onClick={() => {
															setProducts(
																products.filter(
																	(fp) =>
																		!(
																			fp.id ===
																				p.id &&
																			fp.selectedColor ===
																				p.selectedColor
																		),
																),
															);
															addNotification({
																alert_type:
																	'success',
																autohide: true,
																delay: 3,
																text: `Se quito el producto ${p.name} del carrito.`,
																title:
																	'Carrito 🛒',
															});
														}}
													>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															className="w-3 h-3 text-gray-600"
															viewBox="0 0 16 16"
														>
															<path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
														</svg>
													</button>
												</div>
											</div>
										))}
									</div>
									<div className="flex justify-between px-3">
										<div className="inline-block">
											TOTAL
										</div>
										<div className="inline-block">
											{products.reduce(
												(acc, p) => acc + p.price,
												0,
											)}
											$
										</div>
									</div>
									<div>
										<a
											href="/cart"
											className="inline-flex items-center px-1 justify-center py-2 transition-all bg-main-orange text-white border border-main-orange hover:bg-main-white hover:text-white"
										>
											Ver Carrito
										</a>
										<a
											href="/checkout"
											className="inline-flex ml-1 px-1 items-center justify-center py-2 transition-all bg-white text-black border border-main-orange hover:bg-main-orange hover:text-white "
										>
											Checkout
											<svg
												xmlns="http://www.w3.org/2000/svg"
												className="w-3 h-3"
												fill="currentColor"
												viewBox="0 0 16 16"
											>
												<path
													fillRule="evenodd"
													d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
												/>
											</svg>
										</a>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
			<div
				className="py-4 lg:px-32 md:px-16 x-4 bg-main-dark text-white w-full transition-all duration-500 -top-8"
				ref={navRef}
			>
				<nav className="flex flex-row text-left justify-between text-sm uppercase items-baseline w-full">
					<div className="mb-0 md:block hidden">
						<a
							href="/"
							className={`nav-link ${
								actualScreen === 'Inicio' &&
								'active pointer-events-none'
							}`}
						>
							Inicio
						</a>
						<div
							className="group relative inline-block"
							onMouseEnter={() => {
								if (categoriesDropdownRef.current) {
									categoriesDropdownRef.current.classList.remove(
										'pointer-events-none',
									);
									categoriesDropdownRef.current.classList.add(
										'pointer-events-auto',
									);
								}
							}}
							onMouseLeave={() => {
								if (categoriesDropdownRef.current) {
									categoriesDropdownRef.current.classList.remove(
										'pointer-events-auto',
									);
									categoriesDropdownRef.current.classList.add(
										'pointer-events-none',
									);
								}
							}}
						>
							<a
								href="/tienda"
								className={`nav-link ${
									actualScreen === 'Tienda' &&
									'active pointer-events-none '
								}`}
							>
								Tienda
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="16"
									height="16"
									fill="red"
									className="ml-2 inline-block"
									viewBox="0 0 16 16"
								>
									<path
										fillRule="evenodd"
										d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
									/>
								</svg>
							</a>
							<div
								className="categories-dropdown pointer-events-none"
								ref={categoriesDropdownRef}
							>
								<a
									href="/tienda/hombre"
									className={`nav-link my-2 ${
										actualScreen === 'Tienda' &&
										'active pointer-events-none '
									}`}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="currentColor"
										className="w-4 h-4 inline-block"
										viewBox="0 0 16 16"
									>
										<path
											fillRule="evenodd"
											d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
										/>
									</svg>
									Para Hombres
								</a>
								<a
									href="/tienda/mujer"
									className={`nav-link my-2 ${
										actualScreen === 'Tienda' &&
										'active pointer-events-none '
									}`}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="currentColor"
										className="w-4 h-4 inline-block"
										viewBox="0 0 16 16"
									>
										<path
											fillRule="evenodd"
											d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
										/>
									</svg>
									Para Mujeres
								</a>
								{data?.categorias?.map((c) => (
									<a
										key={c?.id}
										href={`/tienda/${c?.Nombre}`}
										className={`nav-link my-2 ${
											actualScreen === 'Tienda' &&
											'active pointer-events-none '
										}`}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="currentColor"
											className="w-4 h-4 inline-block"
											viewBox="0 0 16 16"
										>
											<path
												fillRule="evenodd"
												d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
											/>
										</svg>
										{c?.Nombre}
									</a>
								))}
							</div>
						</div>
						<a
							href="/somos"
							className={`nav-link ${
								actualScreen === 'Somos' &&
								'active pointer-events-none '
							}`}
						>
							Somos
						</a>
						<a
							href="/promos"
							className={`nav-link ${
								actualScreen === 'Promos' &&
								'active pointer-events-none '
							}`}
						>
							Promos
						</a>
						{/* <a
							href="/blog"
							className={`nav-link ${
								actualScreen === 'Blog' &&
								'active pointer-events-none '
							}`}
						>
							Blog
						</a> */}
						<a
							href="/contacto"
							className={`nav-link ${
								actualScreen === 'Contacto' &&
								'active pointer-events-none '
							}`}
						>
							Contacto
						</a>
					</div>
					<button
						type="button"
						className="block md:hidden ring-0"
						onClick={() => setShowSidebar(true)}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="currentColor"
							className="w-7 h-7"
							viewBox="0 0 16 16"
						>
							<path
								fillRule="evenodd"
								d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
							/>
						</svg>
					</button>

					<div className="inline-block">
						<svg
							aria-hidden="true"
							role="img"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 640 512"
							className="inline-block text-red-600 w-4 h-4 transform md:translate-y-0 -translate-y-2.5"
						>
							<path
								fill="currentColor"
								d="M320,64A112.14,112.14,0,0,0,208,176a16,16,0,0,0,32,0,80.09,80.09,0,0,1,80-80,16,16,0,0,0,0-32Zm0-64C217.06,0,143.88,83.55,144,176.23a175,175,0,0,0,43.56,115.55C213.22,321,237.84,368.69,240,384l.06,75.19a15.88,15.88,0,0,0,2.69,8.83l24.5,36.84A16,16,0,0,0,280.56,512h78.85a16,16,0,0,0,13.34-7.14L397.25,468a16.17,16.17,0,0,0,2.69-8.83L400,384c2.25-15.72,27-63.19,52.44-92.22A175.9,175.9,0,0,0,320,0Zm47.94,454.31L350.84,480H289.12l-17.06-25.69,0-6.31h95.91ZM368,416H272l-.06-32H368Zm60.41-145.31c-14,15.95-36.32,48.09-50.57,81.29H262.22c-14.28-33.21-36.6-65.34-50.6-81.29A143.47,143.47,0,0,1,176.06,176C175.88,99,236.44,32,320,32c79.41,0,144,64.59,144,144A143.69,143.69,0,0,1,428.38,270.69ZM96,176a16,16,0,0,0-16-16H16a16,16,0,0,0,0,32H80A16,16,0,0,0,96,176ZM528,64a16.17,16.17,0,0,0,7.16-1.69l64-32A16,16,0,0,0,584.84,1.69l-64,32A16,16,0,0,0,528,64Zm96,96H560a16,16,0,0,0,0,32h64a16,16,0,0,0,0-32ZM119.16,33.69l-64-32A16,16,0,0,0,40.84,30.31l64,32A16.17,16.17,0,0,0,112,64a16,16,0,0,0,7.16-30.31Zm480,288-64-32a16,16,0,0,0-14.32,28.63l64,32a16,16,0,0,0,14.32-28.63ZM112,288a16.17,16.17,0,0,0-7.16,1.69l-64,32a16,16,0,0,0,14.32,28.63l64-32A16,16,0,0,0,112,288Z"
							/>
						</svg>
						&nbsp;
						<span className="md:inline-block hidden uppercase">
							{(data?.headers &&
								data.headers[0] &&
								data.headers[0].TextoDescuento) ??
								'Descuento hasta del 40%'}
						</span>
					</div>
				</nav>
				<nav
					className="flex fixed top-0 h-screen w-screen text-sm transform transition-all duration-300 z-50"
					style={{
						left: showSidebar ? 0 : '-100vw',
						opacity: showSidebar ? 1 : 0,
					}}
				>
					<div
						className="w-8/12 bg-main-gray text-white py-4"
						ref={sidebarRef}
					>
						<button
							type="button"
							className="ml-56"
							onClick={() => setShowSidebar(false)}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="currentColor"
								className="w-5 h-5"
								viewBox="0 0 16 16"
							>
								<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
							</svg>
						</button>
						<ul>
							<li className="px-4 py-2 border-b border-gray-500">
								<a href="/">Inicio</a>
							</li>
							<li className="px-4 py-2 border-b border-gray-500">
								<a href="/tienda">Tienda</a>
							</li>
							<li className="px-4 py-2 border-b border-gray-500">
								<a href="/somos">Somos</a>
							</li>
							<li className="px-4 py-2 border-b border-gray-500">
								<a href="/promos">Promos</a>
							</li>
							{/* <li className="px-4 py-2 border-b border-gray-500">
								<a href="/blog">Blog</a>
							</li> */}
							<li className="px-4 py-2 border-b border-gray-500">
								<a href="/contacto">Contacto</a>
							</li>
						</ul>
						<div className="inline-block text-gray-500 mt-5 mx-auto ml-24">
							<div className="inline-block">
								<a
									href={
										(data?.headers &&
											data.headers[0] &&
											data.headers[0].Facebook) ??
										'https://facebook.com'
									}
									rel="noreferrer"
									target="_blank"
									className="flex items-center justify-center w-8 h-8 rounded-full mr-2 border border-gray-500"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										aria-hidden="true"
										focusable="false"
										role="img"
										className="inline-block w-4 h-4"
										viewBox="0 0 320 512"
									>
										<path
											fill="currentColor"
											d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
										/>
									</svg>
								</a>
							</div>
							<div className="inline-block">
								<a
									href={
										(data?.headers &&
											data.headers[0] &&
											data.headers[0].Instagram) ??
										'https://instagram.com'
									}
									rel="noreferrer"
									target="_blank"
									className="flex items-center justify-center w-8 h-8 rounded-full border border-gray-500"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="currentColor"
										className="inline-block w-4 h-4"
										viewBox="0 0 16 16"
									>
										<path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
									</svg>
								</a>
							</div>
						</div>
					</div>
					<span className="w-4/12 opacity-50 bg-gray-800" />
				</nav>
			</div>
			<div className="flex fixed top-0 h-screen w-screen text-sm transform transition-all duration-300 z-0 pointer-events-none">
				<div className="sticky w-full h-full pointer-events-none">
					{showWhatsappTray && (
						<div className="absolute h-32 pt-4 w-64 right-12 bottom-24 z-30 bg-gray-100 rounded-lg shadow-lg pointer-events-auto">
							<button
								type="button"
								className="focus:outline-none ml-1.5 z-40 absolute -top-1 -right-1 bg-green-400 rounded-full p-2"
								onClick={() => setShowWhatsappTray(false)}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="w-3 h-3 text-white"
									fill="currentColor"
									viewBox="0 0 16 16"
								>
									<path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
								</svg>
							</button>
							<a
								href={(
									(data?.footers &&
										data.footers[0] &&
										data.footers[0].LinkWhatsapp) ??
									'https://api.whatsapp.com/send/?phone=573504538519&text=Me+gustar%C3%ADa+saber+el+precio+del+producto&app_absent=0'
								).replaceAll(/(\+| )/g, '')}
								rel="noreferrer"
								target="_blank"
								className="relative flex w-full px-1"
							>
								<div className="inline-block w-2/5">
									<img
										className="max-h-full max-w-full rounded-full"
										src="https://res.cloudinary.com/kurtcovayne4/image/upload/v1621638360/eros/Whatsapp_thol3l.jpg"
										alt="Contacto"
									/>
								</div>
								<div className="inline-block w-3/5 ">
									<div className="flex flex-col justify-between items-center">
										<span className="text-lg mb-4 text-center mx-auto">
											Atención Eros
										</span>
										<span className="relative inline-flex rounded-md shadow-sm">
											<div className="inline-flex items-center px-2 py-1 border border-green-400 text-base leading-6 font-medium rounded-md text-green-800 bg-white hover:text-green-700 focus:border-green-300 transition ease-in-out duration-150">
												Conectado
											</div>
											<span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
												<span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75" />
												<span className="relative inline-flex rounded-full h-3 w-3 bg-green-500" />
											</span>
										</span>
									</div>
								</div>
							</a>
						</div>
					)}

					<button
						type="button"
						className="group whatsapp-animation absolute bottom-10 bg-green-600 rounded-full p-3 cursor-pointer pointer-events-auto focus:outline-none"
						onClick={() => setShowWhatsappTray((wp) => !wp)}
					>
						<span className="inline-block text-gray-200 mx-2">
							Whatsapp
						</span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="inline-block w-6 h-6 text-gray-200 transition-all duration-300 group-hover:text-white"
							fill="currentColor"
							viewBox="0 0 16 16"
						>
							<path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
						</svg>
					</button>
					<button
						type="button"
						className="group absolute right-16 bottom-32 bg-gray-200 p-3 cursor-pointer pointer-events-auto focus:outline-none hidden"
						ref={upButtonRef}
						onClick={() => {
							if (topRef.current) {
								topRef.current.scrollIntoView({
									behavior: 'smooth',
								});
							}
						}}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="w-7 h-6 text-gray-400 transition-all duration-300 delay-75 group-hover:text-gray-500"
							fill="currentColor"
							viewBox="0 0 16 16"
						>
							<path
								fillRule="evenodd"
								d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
							/>
						</svg>
					</button>
				</div>
			</div>
		</header>
	);
};
Nav.defaultProps = {
	actualScreen: undefined,
};
export default Nav;
