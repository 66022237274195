export const fancyTimeFormat: (seconds: number) => string = (
	seconds: number,
) => {
	const hrs = Math.floor(seconds / 3600);
	const mins = Math.floor((seconds % 3600) / 60);
	const secs = Math.floor(seconds) % 60;

	if (hrs > 0) {
		return `${hrs} horas, ${mins} minutos y ${secs} segundos`;
	}
	if (mins > 0) {
		return `${mins} minutos y ${secs} segundos`;
	}
	return `${secs} segundos`;
};

export const roundNumber = (presicion: number, num: number): string =>
	(Math.round(num * 100) / 100).toFixed(presicion);

export const formatPrice: (value: number) => string = (value: number) => {
	// Find a way to format properly, by now, just round to 2 decimals..
	// const re = /\d(?=(\d{3})+\D)/;
	// const num = value.toFixed(Math.max(0, 2));
	// return num.replace(new RegExp(re, 'g'), '$&,');
	return roundNumber(2, value);
};
