/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import Carousel from '../components/Carousel';
import Footer from '../components/Footer';
import Product from '../components/Product';
import Nav from '../components/Nav';
import '../styles/Land.css';
import {
	RegularPopupFragment,
	useAddSuscriptionMutation,
	useGetLandQuery,
} from '../generated/graphql';
import Popup from '../components/Popup';
import useLocalStorage from '../utils/useLocalStorage';

const items = [
	{
		img_url:
			'https://res.cloudinary.com/kurtcovayne4/image/upload/v1620674604/eros/Ajustes_Web_Eros_1_-_Copy_wcdmti.jpg',
	},
	{
		img_url:
			'https://tienda-shop-imagenes.s3.amazonaws.com/slide_2_bc45c0524b.jpg',
	},
	{
		img_url:
			'https://tienda-shop-imagenes.s3.amazonaws.com/slide_2_bc45c0524b.jpg',
	},
];

const LandScreen: React.FC = () => {
	const [categorySelected, setCategorySelected] = useState<string>('Todos');

	const [where, setWhere] = useState<Record<string, unknown>>({
		Destacado: true,
	});
	const { data, loading } = useGetLandQuery({
		variables: { where },
	});

	useEffect(() => {
		if (categorySelected === 'Todos') {
			setWhere({
				Destacado: true,
			});
			return;
		}
		if (data?.categorias) {
			for (let i = 0; i < data.categorias.length; i += 1) {
				const categoria = data.categorias[i];
				if (categoria?.id === categorySelected) {
					setWhere({
						Destacado: true,
						categoria: { id: categoria.id },
					});
					return;
				}
			}
		}
	}, [categorySelected, data]);

	const [showPopup, setShowPopup] = useState(false);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setShowPopup(true);
		}, 5000);

		return () => clearTimeout(timeout);
	}, []);

	const [email, setEmail] = useState('');

	const [
		AddSuscriptor,
		{ loading: mutationLoading },
	] = useAddSuscriptionMutation();

	const [suscribed, setSuscribed] = useLocalStorage<boolean>(
		'suscribed',
		false,
	);

	return (
		<>
			<Nav actualScreen="Inicio" />
			<section
				className="h-auto p-0 m-0 mb-5 mx-auto"
				style={{ width: '95%' }}
			>
				<Carousel
					items={
						data
							? data.pruebas?.map((portada) =>
									portada
										? {
												botonTitle:
													portada.TextoBoton ?? '',
												img_url:
													(portada.Imagen_1 &&
														portada.Imagen_1[0] &&
														portada.Imagen_1[0]
															.url) ??
													'https://res.cloudinary.com/kurtcovayne4/image/upload/v1620674604/eros/Ajustes_Web_Eros_1_-_Copy_wcdmti.jpg',
												id: portada.id,
												botonUrl: portada.UrlBoton,
										  }
										: items[0],
							  ) ?? items
							: items
					}
				/>
			</section>
			<section className="category-container">
				<div className="flex flex-wrap -mx-1 ">
					<a href="/tienda/mujer" className="category-banner">
						<div className="text-center absolute w-full top-1/2 transform -translate-y-1/2">
							<h3
								className="text-white text-1xl font-bold uppercase select-none"
								role="presentation"
							>
								{(data?.categoriasPrincipales &&
									data?.categoriasPrincipales[0] &&
									data?.categoriasPrincipales[0]
										.TituloMujer) ??
									'Nuevo en categoria'}
							</h3>
							<h2
								className="text-white text-3xl font-bold uppercase select-none"
								role="presentation"
							>
								{(data?.categoriasPrincipales &&
									data?.categoriasPrincipales[0] &&
									data?.categoriasPrincipales[0]
										.SubtituloMujer) ??
									'Mujer'}
							</h2>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="group w-60 h-30 mx-auto my-3 text-red-600 hover:text-main-gray transition-all duration-200 bg-transparent"
								fill="currentColor"
								viewBox="0 0 744 183"
							>
								<path
									d="M625.73,596.76c2.69-12.72,5.47-25,7.85-37.39,1.41-7.28,1.25-14.64-1.87-21.58a25.61,25.61,0,0,0-11.64-12.55,100.65,100.65,0,0,1-16.31-10.52c-6.33-5.19-5.8-10.1.68-15.16.39-.31.81-.59,1.22-.88,6.8-4.75,6.8-4.75,5.89-12.48,15.64-.69,31.24-1.63,46.85-2.05q111.95-3,223.89-5.8c30.32-.77,60.63-1.6,91-2.08q106-1.68,212-2.93c15.29-.16,30.62,1,45.9,1.92,5.31.34,11.21.63,13.48,7.4.28.85,2.76,1.35,4.23,1.38,8.5.12,17-.09,25.5.14a31.47,31.47,0,0,1,9.73,1.73c4.62,1.67,5.35,6.24,2.35,10.14a24.57,24.57,0,0,0-3.61,6.78c-1.86,5.36-5.72,8.06-11,8.86-4.41.67-8.88,1-13.44,2.69,5.62.56,11.26,1,16.86,1.72,6.74.87,13.65,1.31,20.15,3.15,10.54,3,15.71,10.51,14.8,19.71a18.52,18.52,0,0,1-18.27,16.73c-17.28.58-34.6-.15-51.9-.37-3.17,0-6.33-.47-9.49-.34-1.58.06-3.12,1-4.68,1.48,0,.61.06,1.22.1,1.83,5.28.6,10.57,1.17,15.85,1.82,7.75.94,15.62,1.4,23.22,3,9.76,2.11,15.22,9.26,15,17.94A17.55,17.55,0,0,1,1264,597.67c-9.86,1.13-19.92.78-29.89.78-14.16,0-28.32-.29-42.49-.34-2.74,0-4.33-.52-5.55-3.5-3-7.45-9.56-10.12-17-9.69-13.29.77-26.55,2.15-39.83,3.24-18.91,1.55-37.81,3.56-56.75,4.51-83.09,4.17-166.27,3.25-249.42,3.58q-96.5.39-193,.51Z"
									transform="translate(-588 -448)"
								/>
								<text
									xmlns="http://www.w3.org/2000/svg"
									className="text-white group-hover:text-white transition-all duration-200"
									fill="currentColor"
									fontSize="3rem"
								>
									<tspan x="180" y="100">
										{(data?.categoriasPrincipales &&
											data?.categoriasPrincipales[0] &&
											data?.categoriasPrincipales[0]
												.TituloBotonMujer) ??
											'Comprar ahora'}
									</tspan>
								</text>
							</svg>
						</div>
						<div
							className="flex items-center w-full h-full"
							style={{
								backgroundSize: 'cover',
								backgroundPosition: 'center center',
								backgroundImage: `url(${
									(data?.categoriasPrincipales &&
										data.categoriasPrincipales[0] &&
										data.categoriasPrincipales[0]
											.ImagenMujer &&
										data.categoriasPrincipales[0]
											.ImagenMujer[0]?.url) ??
									'https://res.cloudinary.com/kurtcovayne4/image/upload/v1617652593/eros/banner-1_zy1por.jpg'
								})`,
							}}
						/>
					</a>

					<a href="/tienda/hombre" className="category-banner">
						<div className="text-center absolute w-full top-1/2 transform -translate-y-1/2">
							<h3
								className="text-white text-1xl font-bold uppercase select-none"
								role="presentation"
							>
								{(data?.categoriasPrincipales &&
									data?.categoriasPrincipales[0] &&
									data?.categoriasPrincipales[0]
										.TituloHombre) ??
									'Nuevo en categoria'}
							</h3>
							<h2
								className="text-white text-3xl font-bold uppercase select-none"
								role="presentation"
							>
								{(data?.categoriasPrincipales &&
									data?.categoriasPrincipales[0] &&
									data?.categoriasPrincipales[0]
										.SubtituloHombre) ??
									'Hombre'}
							</h2>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="group w-60 h-30 mx-auto my-3 text-red-600 hover:text-main-gray transition-all duration-200 bg-transparent"
								fill="currentColor"
								viewBox="0 0 744 183"
							>
								<path
									d="M625.73,596.76c2.69-12.72,5.47-25,7.85-37.39,1.41-7.28,1.25-14.64-1.87-21.58a25.61,25.61,0,0,0-11.64-12.55,100.65,100.65,0,0,1-16.31-10.52c-6.33-5.19-5.8-10.1.68-15.16.39-.31.81-.59,1.22-.88,6.8-4.75,6.8-4.75,5.89-12.48,15.64-.69,31.24-1.63,46.85-2.05q111.95-3,223.89-5.8c30.32-.77,60.63-1.6,91-2.08q106-1.68,212-2.93c15.29-.16,30.62,1,45.9,1.92,5.31.34,11.21.63,13.48,7.4.28.85,2.76,1.35,4.23,1.38,8.5.12,17-.09,25.5.14a31.47,31.47,0,0,1,9.73,1.73c4.62,1.67,5.35,6.24,2.35,10.14a24.57,24.57,0,0,0-3.61,6.78c-1.86,5.36-5.72,8.06-11,8.86-4.41.67-8.88,1-13.44,2.69,5.62.56,11.26,1,16.86,1.72,6.74.87,13.65,1.31,20.15,3.15,10.54,3,15.71,10.51,14.8,19.71a18.52,18.52,0,0,1-18.27,16.73c-17.28.58-34.6-.15-51.9-.37-3.17,0-6.33-.47-9.49-.34-1.58.06-3.12,1-4.68,1.48,0,.61.06,1.22.1,1.83,5.28.6,10.57,1.17,15.85,1.82,7.75.94,15.62,1.4,23.22,3,9.76,2.11,15.22,9.26,15,17.94A17.55,17.55,0,0,1,1264,597.67c-9.86,1.13-19.92.78-29.89.78-14.16,0-28.32-.29-42.49-.34-2.74,0-4.33-.52-5.55-3.5-3-7.45-9.56-10.12-17-9.69-13.29.77-26.55,2.15-39.83,3.24-18.91,1.55-37.81,3.56-56.75,4.51-83.09,4.17-166.27,3.25-249.42,3.58q-96.5.39-193,.51Z"
									transform="translate(-588 -448)"
								/>
								<text
									xmlns="http://www.w3.org/2000/svg"
									className="text-white group-hover:text-white transition-all duration-200"
									fill="currentColor"
									fontSize="3rem"
								>
									<tspan x="180" y="100">
										{(data?.categoriasPrincipales &&
											data?.categoriasPrincipales[0] &&
											data?.categoriasPrincipales[0]
												.TituloBotonHombre) ??
											'Comprar ahora'}
									</tspan>
								</text>
							</svg>
						</div>
						<div
							className="flex items-center w-full h-full"
							style={{
								backgroundSize: 'cover',
								backgroundPosition: 'center center',
								backgroundImage: `url(${
									(data?.categoriasPrincipales &&
										data.categoriasPrincipales[0] &&
										data.categoriasPrincipales[0]
											.ImagenHombre &&
										data.categoriasPrincipales[0]
											.ImagenHombre[0]?.url) ??
									'https://res.cloudinary.com/kurtcovayne4/image/upload/v1617652593/eros/banner-2_b5o31u.jpg'
								})`,
							}}
						/>
					</a>
				</div>
			</section>
			<span className="block border-b w-full h-px mb-28 border-gray-200" />

			<section className="text-center py-1 mb-8 px-2 md:px-12 lg:px-20 full mx-auto">
				<h3 className="text-3xl text-main-brown font-bold uppercase mb-4">
					Productos destacados
				</h3>
				<div className="flex mx-auto items-center justify-center mb-8">
					<button
						type="button"
						onClick={() => setCategorySelected('Todos')}
						className={`relative animated-link focus:outline-none ${
							categorySelected === 'Todos' && 'active'
						}`}
					>
						<h4 className="text-base ml-1 mr-3.5 ">Todos</h4>
					</button>
					{data?.categorias?.map((c) => (
						<React.Fragment key={c?.id}>
							{c?.id && (
								<button
									type="button"
									onClick={() => setCategorySelected(c.id)}
									className={`relative animated-link focus:outline-none ${
										categorySelected === c?.id && 'active'
									}`}
								>
									<h4 className="text-base ml-1 mr-3.5 ">
										{c.Nombre}
									</h4>
								</button>
							)}
						</React.Fragment>
					))}
				</div>

				<div
					className="flex flex-wrap overflow-hidden mx-auto"
					style={{ width: '95%' }}
				>
					{!data?.productos && loading && (
						<div className="w-full">
							<h2 className="mx-auto text-xl my-44">
								Cargando productos...
							</h2>
						</div>
					)}
					{data?.productos?.length === 0 && (
						<div className="w-full">
							<h2 className="mx-auto text-xl my-44">
								¡No hay productos Destacados en esta categoria!
							</h2>
						</div>
					)}
					{data?.productos?.map((product) => (
						<>
							{product && (
								<Product
									key={product.id}
									id={product.id ?? '1000'}
									name={product.Titulo ?? ''}
									image={
										(product.Imagenes &&
											product.Imagenes[0] &&
											product.Imagenes[0].url) ??
										'https://res.cloudinary.com/kurtcovayne4/image/upload/v1621286530/plain-gray-background_e1ff71.jpg'
									}
									categories={product.categoria?.Nombre ?? ''}
									price={product.precio}
									selectedColor={
										(product.colores &&
											product.colores[0]?.NombreColor) ??
										null
									}
									topText={
										(product.Descuento
											? 'Descuento'
											: undefined) ||
										(product.Nuevo ? 'Nuevo' : undefined)
									}
									SKU={product.SKU ?? ''}
								/>
							)}
						</>
					))}
				</div>
			</section>
			<section className="mx-auto lg:px-32 md:px-40 px-8 m-0 md:mb-14">
				<div className="subscribe-part">
					<div className="relative my-1 px-1 w-full overflow-hidden md:w-1/2 hover:opacity-95">
						<div className="text-center absolute top-1/2 transform -translate-y-1/2 w-full md:px-16 px-8">
							<h3
								className="text-main-brown text-4xl font-black select-none mb-4"
								role="presentation"
							>
								{(data?.generoCategorias &&
									data.generoCategorias[0] &&
									data.generoCategorias[0].Titulo) ??
									'¿Quieres vender nuestros productos?'}
							</h3>
							<a
								type="button"
								href={
									(data?.generoCategorias &&
										data.generoCategorias[0] &&
										data.generoCategorias[0]
											.LinkEscribenos) ??
									'/tienda'
								}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="group w-60 h-30 mx-auto my-3 text-red-600 hover:text-main-gray transition-all duration-200 bg-transparent"
									fill="currentColor"
									viewBox="0 0 744 183"
								>
									<path
										d="M625.73,596.76c2.69-12.72,5.47-25,7.85-37.39,1.41-7.28,1.25-14.64-1.87-21.58a25.61,25.61,0,0,0-11.64-12.55,100.65,100.65,0,0,1-16.31-10.52c-6.33-5.19-5.8-10.1.68-15.16.39-.31.81-.59,1.22-.88,6.8-4.75,6.8-4.75,5.89-12.48,15.64-.69,31.24-1.63,46.85-2.05q111.95-3,223.89-5.8c30.32-.77,60.63-1.6,91-2.08q106-1.68,212-2.93c15.29-.16,30.62,1,45.9,1.92,5.31.34,11.21.63,13.48,7.4.28.85,2.76,1.35,4.23,1.38,8.5.12,17-.09,25.5.14a31.47,31.47,0,0,1,9.73,1.73c4.62,1.67,5.35,6.24,2.35,10.14a24.57,24.57,0,0,0-3.61,6.78c-1.86,5.36-5.72,8.06-11,8.86-4.41.67-8.88,1-13.44,2.69,5.62.56,11.26,1,16.86,1.72,6.74.87,13.65,1.31,20.15,3.15,10.54,3,15.71,10.51,14.8,19.71a18.52,18.52,0,0,1-18.27,16.73c-17.28.58-34.6-.15-51.9-.37-3.17,0-6.33-.47-9.49-.34-1.58.06-3.12,1-4.68,1.48,0,.61.06,1.22.1,1.83,5.28.6,10.57,1.17,15.85,1.82,7.75.94,15.62,1.4,23.22,3,9.76,2.11,15.22,9.26,15,17.94A17.55,17.55,0,0,1,1264,597.67c-9.86,1.13-19.92.78-29.89.78-14.16,0-28.32-.29-42.49-.34-2.74,0-4.33-.52-5.55-3.5-3-7.45-9.56-10.12-17-9.69-13.29.77-26.55,2.15-39.83,3.24-18.91,1.55-37.81,3.56-56.75,4.51-83.09,4.17-166.27,3.25-249.42,3.58q-96.5.39-193,.51Z"
										transform="translate(-588 -448)"
									/>
									<text
										xmlns="http://www.w3.org/2000/svg"
										className="text-white group-hover:text-white transition-all duration-200"
										fill="currentColor"
										fontSize="5rem"
										fontWeight="600"
									>
										<tspan x="135" y="110">
											{(data?.generoCategorias &&
												data.generoCategorias[0] &&
												data.generoCategorias[0]
													.TituloBoton) ??
												'Escríbenos'}
										</tspan>
									</text>
								</svg>
							</a>
						</div>
						<div
							className="flex items-center w-full h-full"
							style={{
								backgroundSize: 'cover',
								backgroundPosition: 'center center',
								backgroundImage: `url(${
									(data?.generoCategorias &&
										data.generoCategorias[0] &&
										data.generoCategorias[0].Imagen?.url) ??
									'https://res.cloudinary.com/kurtcovayne4/image/upload/v1621474540/eros/Banner1_dy7ogw.jpg'
								})`,
							}}
						/>
					</div>

					<div className="block my-1 text-center px-5 py-2 w-full h-80 overflow-hidden md:w-1/2 shadow-sm bg-gray-100">
						<div className="relative w-full h-full">
							<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
								{suscribed ? (
									<h4 className="text-lg font-bold">
										Gracias por suscribirte a nuestro
										boletín
									</h4>
								) : (
									<>
										<h3 className="text-lg font-bold uppercase">
											¡Suscríbete!
										</h3>
										<h3 className="text-base mb-4 px-4">
											para novedades y promociones
										</h3>
										<input
											className="border border-black transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent p-1.5 w-full"
											type="email"
											placeholder="Ingresa tu E-mail"
											value={email}
											onChange={(e) =>
												setEmail(e.currentTarget.value)
											}
										/>
										<button
											type="button"
											className={`focus:outline-none ${
												mutationLoading
													? 'opacity-50'
													: 'opcity-100'
											}`}
											disabled={mutationLoading}
											onClick={async () => {
												setSuscribed(true);
												AddSuscriptor({
													variables: {
														input: {
															data: {
																Correo: email,
															},
														},
													},
												});
											}}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												className="group w-60 h-30 mx-auto my-3 text-red-600 hover:text-main-gray transition-all duration-200 bg-transparent"
												fill="currentColor"
												viewBox="0 0 744 183"
											>
												<path
													d="M625.73,596.76c2.69-12.72,5.47-25,7.85-37.39,1.41-7.28,1.25-14.64-1.87-21.58a25.61,25.61,0,0,0-11.64-12.55,100.65,100.65,0,0,1-16.31-10.52c-6.33-5.19-5.8-10.1.68-15.16.39-.31.81-.59,1.22-.88,6.8-4.75,6.8-4.75,5.89-12.48,15.64-.69,31.24-1.63,46.85-2.05q111.95-3,223.89-5.8c30.32-.77,60.63-1.6,91-2.08q106-1.68,212-2.93c15.29-.16,30.62,1,45.9,1.92,5.31.34,11.21.63,13.48,7.4.28.85,2.76,1.35,4.23,1.38,8.5.12,17-.09,25.5.14a31.47,31.47,0,0,1,9.73,1.73c4.62,1.67,5.35,6.24,2.35,10.14a24.57,24.57,0,0,0-3.61,6.78c-1.86,5.36-5.72,8.06-11,8.86-4.41.67-8.88,1-13.44,2.69,5.62.56,11.26,1,16.86,1.72,6.74.87,13.65,1.31,20.15,3.15,10.54,3,15.71,10.51,14.8,19.71a18.52,18.52,0,0,1-18.27,16.73c-17.28.58-34.6-.15-51.9-.37-3.17,0-6.33-.47-9.49-.34-1.58.06-3.12,1-4.68,1.48,0,.61.06,1.22.1,1.83,5.28.6,10.57,1.17,15.85,1.82,7.75.94,15.62,1.4,23.22,3,9.76,2.11,15.22,9.26,15,17.94A17.55,17.55,0,0,1,1264,597.67c-9.86,1.13-19.92.78-29.89.78-14.16,0-28.32-.29-42.49-.34-2.74,0-4.33-.52-5.55-3.5-3-7.45-9.56-10.12-17-9.69-13.29.77-26.55,2.15-39.83,3.24-18.91,1.55-37.81,3.56-56.75,4.51-83.09,4.17-166.27,3.25-249.42,3.58q-96.5.39-193,.51Z"
													transform="translate(-588 -448)"
												/>
												<text
													xmlns="http://www.w3.org/2000/svg"
													className="text-white group-hover:text-white transition-all duration-200"
													fill="currentColor"
													fontSize="3rem"
													fontWeight="900"
												>
													<tspan x="225" y="100">
														Suscríbete
													</tspan>
												</text>
											</svg>
										</button>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
			{showPopup &&
				data?.ventanasEmergentes &&
				data?.ventanasEmergentes[0] && (
					<Popup
						id={data?.ventanasEmergentes[0].id ?? ''}
						Habilitado={
							data?.ventanasEmergentes[0].Habilitado ?? false
						}
						Imagen={
							(data?.ventanasEmergentes[0].Imagen &&
								data?.ventanasEmergentes[0].Imagen[0] &&
								data?.ventanasEmergentes[0].Imagen) ??
							({} as RegularPopupFragment['Imagen'])
						}
						PorcentajeDescuento={
							data?.ventanasEmergentes[0].PorcentajeDescuento ??
							''
						}
						Texto={data?.ventanasEmergentes[0].Texto ?? ''}
						TextoBoton={
							data?.ventanasEmergentes[0].TextoBoton ?? ''
						}
						hide={() => setShowPopup(false)}
					/>
				)}
		</>
	);
};

export default LandScreen;
