import React, { useContext, useState, useCallback, ReactNode } from 'react';
import Notification from '../components/Notification';
import { NotificationType } from '../types';
import { generateUUID } from '../utils/notificationsUtils';

type InternalNotification = NotificationType & {
	id: string;
};
type NotificationContextType = [
	InternalNotification[],
	(notification: NotificationType) => string,
	(id: string) => void,
];
const NotificationContext = React.createContext<NotificationContextType>([
	[],
	() => '',
	() => {},
]);

export const useNotifications: () => NotificationContextType = () =>
	useContext(NotificationContext);

const NotificationContainer: React.FC<{ children: ReactNode }> = ({
	children,
}: {
	children: ReactNode;
}) => {
	const [StackedNotifications, setStackedNotifications] = useState<
		Array<InternalNotification>
	>(new Array<InternalNotification>());

	const closeNotification = useCallback(
		(id: string) => {
			setStackedNotifications(
				StackedNotifications.flatMap((notification) =>
					notification.id === id ? [] : [notification],
				),
			);
		},
		[StackedNotifications, setStackedNotifications],
	);
	const pushNotification = useCallback(
		(notification: NotificationType) => {
			const id = generateUUID();
			setStackedNotifications(
				[Object.assign(notification, { id })].concat([
					...StackedNotifications,
				]),
			);
			return id;
		},
		[StackedNotifications, setStackedNotifications],
	);
	return (
		<NotificationContext.Provider
			value={[StackedNotifications, pushNotification, closeNotification]}
		>
			<div aria-live="polite" aria-atomic="true" className="relative">
				<div
					className="toast-container fixed top-0 right-0 p-3"
					style={{ zIndex: 1024 }}
				>
					{StackedNotifications.map(
						({ autohide, alert_type, delay, text, title, id }) => (
							<Notification
								key={id}
								title={title}
								alert_type={alert_type}
								text={text}
								autohide={autohide}
								delay={delay}
								close={() => closeNotification(id)}
							/>
						),
					)}
				</div>
			</div>
			{children}
		</NotificationContext.Provider>
	);
};

export default NotificationContainer;
