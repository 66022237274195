import React from 'react';
import { useNotifications } from '../services/Notifications';
import { ProductProps } from '../types';
import useLocalStorage from '../utils/useLocalStorage';

const Product: React.FC<ProductProps> = ({
	topText,
	categories,
	id,
	name,
	price,
	image,
	selectedColor,
	SKU,
}: ProductProps) => {
	const [products, setProducts] = useLocalStorage<Array<ProductProps>>(
		'products',
		[],
	);
	const [, addNotification] = useNotifications();
	return (
		<div
			className="group relative lg:w-1/4 w-full my-1 px-3 py-3"
			style={{ height: '25rem', marginBottom: '1.375rem' }}
		>
			{topText && (
				<div className="pointer-events-none absolute top-5 left-5 text-sm bg-pink-700 uppercase text-white px-1">
					{topText}
				</div>
			)}

			<div className="mt-64 h-36 bg-white text-left absolute w-full transition-all duration-150 delay-75 opacity-0 group-hover:opacity-100 group-hover:mt-72">
				<button
					type="button"
					className="w-1/2 text-center mt-4 border-r inline-block text-black focus:outline-none"
					onClick={() => {
						addNotification({
							alert_type: 'success',
							autohide: true,
							delay: 3,
							text: `Se añadio ${
								selectedColor ?? ''
							} ${name} al carrito con exito`,
							title: 'Carrito 🛒',
						});
						setProducts([
							...products,
							{
								topText,
								categories,
								id,
								name,
								price,
								selectedColor,
								image,
								SKU,
							},
						]);
					}}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="w-4 h-4 mx-auto transform translate-x-2 text-yellow-500"
						fill="currentColor"
						viewBox="0 0 16 16"
					>
						<path d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9V5.5z" />
						<path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
					</svg>
					<h3
						className="ml-4 text-xs font-bold uppercase select-none hover:underline"
						role="presentation"
					>
						Agregar
					</h3>
				</button>
				<div className="w-1/2 text-center inline-block ">
					<a href={`/producto/${SKU}`}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="w-4 h-4 mx-auto transform translate-x-2 text-yellow-500"
							fill="currentColor"
							viewBox="0 0 487 487"
						>
							<path d="M379,229.65c-25.6,0-46.3,20.8-46.3,46.3c0,6.1,5,11.1,11.1,11.1s11.1-5,11.1-11.1    c0-13.3,10.9-24.2,24.2-24.2c6.1,0,11.1-5,11.1-11.1C390.1,234.55,385.1,229.65,379,229.65z" />
							<path d="M454.5,197.75c-2.7-2.6-5.6-5.1-8.6-7.5l0,0l-41-34.8c-15.8-11.2-34.6-18.3-55-19.7l2.2,2.2l-24.9-25    H327c-6.9-6.3-16.2-10.2-26.3-10.2c-17.4,0-32.1,11.3-37.1,27c-5-3.2-10.8-5.1-17.1-5.5c-1-0.1-2.1-0.1-3.1-0.1    c-7.1,0-13.7,2.1-19.3,5.6c-5.1-15.6-19.8-26.9-37.1-26.9c-10.1,0-19.3,3.8-26.3,10.2h-0.2l-24.9,25l2.2-2.2    c-20.4,1.4-39.3,8.6-55,19.7l-41,34.8l0,0c-3,2.3-5.9,4.8-8.6,7.5C12.7,217.65,0,245.35,0,276.05c0,60,48.8,108.8,108.8,108.8    c55.4,0,101.3-41.7,107.9-95.4c8,4.6,17.3,7.2,27.1,7.2c9.9,0,19.1-2.6,27.1-7.2c6.7,53.6,52.5,95.3,107.9,95.3    c60,0,108.8-48.8,108.8-108.8C487.7,245.25,475,217.55,454.5,197.75z M108.8,350.25c-41,0-74.2-33.2-74.2-74.2    s33.2-74.2,74.2-74.2s74.2,33.2,74.2,74.2C183.1,316.95,149.8,350.25,108.8,350.25z M243.9,265.85c-13.3,0-24.1-10.8-24.1-24.1    c0-13.3,10.8-24.1,24.1-24.1c13.3,0,24.1,10.8,24.1,24.1C268,255.05,257.2,265.85,243.9,265.85z M379,350.25    c-41,0-74.2-33.2-74.2-74.2s33.2-74.2,74.2-74.2s74.2,33.2,74.2,74.2C453.2,316.95,420,350.25,379,350.25z" />
							<path d="M108.8,229.65c-25.6,0-46.3,20.8-46.3,46.3c0,6.1,5,11.1,11.1,11.1c6.1,0,11.1-5,11.1-11.1    c0-13.3,10.9-24.2,24.2-24.2c6.1,0,11.1-5,11.1-11.1C119.9,234.55,114.9,229.65,108.8,229.65z" />
						</svg>
						<h3
							className="ml-4 text-xs font-bold uppercase select-none hover:underline"
							role="presentation"
						>
							Ver mas
						</h3>
					</a>
				</div>
			</div>
			<div
				className="h-20 bg-white text-left absolute w-full md:shadow-none shadow-md group-hover:shadow-lg"
				style={{ marginTop: '19rem' }}
			>
				<div className="w-full border-r inline-block text-center">
					<h3
						className="text-xs md:leading-6 leading-none select-none text-gray-400"
						role="presentation"
					>
						{categories}
					</h3>
					<h3
						className="text-base md:leading-6 leading-none font-bold select-none"
						role="presentation"
					>
						{name}
					</h3>
					<h3 className="text-lg md:leading-6 leading-none font-bold text-red-500">
						${price}
					</h3>
				</div>
			</div>
			<div
				className="flex items-center w-full h-full"
				style={{
					backgroundSize: 'cover',
					backgroundPosition: 'center center',
					backgroundImage: `url(${
						image ??
						'https://res.cloudinary.com/kurtcovayne4/image/upload/v1620764827/eros/Ajustes_Web_Eros_1_-_Copy_xaznka.png'
					})`,
				}}
			/>
		</div>
	);
};
Product.defaultProps = {
	topText: undefined,
};

export default Product;
