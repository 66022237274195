/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import NavInfo from '../components/NavInfo';

const PrivacyScreen: React.FC = () => {
	return (
		<>
			<Nav />
			<section className="text-sm font-light text-gray-500 pt-6 pb-5 mb-6 lg:mb-8 lg:px-24 md:px-16 px-4">
				<NavInfo path={['Inicio', 'Aviso de privacidad']} />
				<h2 className="text-red-600 text-xl font-bold">
					AVISO DE PRIVACIDAD Y AUTORIZACIÓN PARA EL TRATAMIENTO DE
					DATOS PERSONALES
				</h2>
				<p className="mb-2">
					Declaro que soy mayor de edad, y de conformidad a lo
					establecido en la Ley 1581/2012, reglamentada por el Decreto
					1074 de 2015, autorizo expresamente a Imperium Technologies
					S.A.S., para que en su calidad de responsable del
					tratamiento, recolecte, almacene, transfiera, transmita y
					trate de acuerdo con sus políticas de privacidad, todos los
					datos personales que voluntariamente le he suministrado o
					que en el futuro le llegare a suministrar a través de su
					sitio web www.erostienda.co tales como, pero sin limitarte a
					ellos, nombre, edad, identificación, estado civil, sexo,
					fecha de nacimiento, nombre de usuario, dirección física y/o
					electrónica, dirección IP, zona horaria, huellas dactilares
					del dispositivo, información financiera, números de cuenta
					bancaria o tarjetas de crédito. Así mismo, autorizo
					expresamente la recolección y tratamiento de los datos
					personales que Imperium Technologies S.A.S., pueda obtener
					de mi a través de cualquiera de mis redes sociales, así como
					cualquier otra información de carácter privada y/o sensible,
					que de manera voluntaria suministre o llegare a divulgar
					públicamente en el sitio web anunciado.
				</p>

				<p className="mb-3">
					Declaro que he sido informado sobre la finalidad del
					tratamiento de mis datos personales y que el suministro de
					mis datos sensibles y biométricos, cuando a ello hubiere
					lugar, son de carácter facultativo, por lo tanto, si en el
					proceso de suscripción al sitio o al momento de realizar una
					compra debo suministrar algún dato que tenga el carácter de
					sensible, entiendo y acepto que será mi decisión suministrar
					o no dicho dato, para lo cual, de manera previa y expresa
					autorizo el tratamiento de mis tatos sensible o no sensibles
					que haya suministrado que en el futuro llegare a
					suministrar, los cuales deberán ser tratados para los
					siguientes fines:
				</p>
				<div className="mb-6 space-y-2">
					<ul className="list-disc list-inside">
						<li className="px-2">
							Para el registro, seguimiento y control de los
							pedidos realizados.
						</li>
						<li className="px-2">
							Para la elaboración de la correspondiente factura de
							venta
						</li>
						<li className="px-2">
							Para el despacho de los productos.
						</li>
						<li className="px-2">
							Para la gestión, promoción y/o divulgación de
							productos y/o servicios asociados al sitio web.
						</li>
						<li className="px-2">
							Llevar a cabo cualquier actividad publicitaria o de
							mercadeo sobre productos y/o servicios, incluso de
							contenido sexual, así como publicitar ofertas,
							promociones, alianzas, estudios, concursos, o la
							realización de actividades comerciales a través
							redes sociales, transmisión streaming o por
							plataformas de mensajería vía internet, o cualquier
							otro medio de comunicación masiva.
						</li>
						<li className="px-2">
							Compartir e intercambiar con empresas filiales,
							matrices, aliadas y/o con entidades financieras, mi
							información personal contenida en sus bases de
							datos, con la finalidad de conocer mis hábitos de
							consumo, mi comportamiento de pago, así como para la
							realización de actividades de mercadeo de sus
							productos o servicios y enviarme a través de
							cualquier medio publicidad relacionada.
						</li>
						<li className="px-2">
							Contactarme directamente o través de terceras
							personas, bien sea a través de redes sociales,
							servicios de mensajería online, teléfono fijo o
							celular, correo físico o electrónico y en general
							por cualquier otro medio conocido o por conocer, con
							fines exclusivamente comerciales, promocionales o de
							mercadeo, ya sea para ofrecerme productos o
							servicios propios, o de terceros, incluso aquellos
							que tengan contenido sexual.
						</li>
					</ul>
					<p className="mb-3">
						Así mismo, entiendo que mi información personal no será
						utilizada o tratada con propósitos diferentes a los aquí
						indicados, por tal motivo, me han informado que tendré
						derecho acceder y conocer sin limitación alguna toda la
						información personal sobre mi recaudada, así como a
						solicitar en cualquier momento la modificación,
						supresión o rectificación de mis datos personales,
						pudiendo ejercer los demás derechos que la Ley prevé,
						siguiendo los procedimientos establecidos por Imperium
						Technologies S.A.S , los cuales se exponen en su
						Política de Privacidad, a la que podré acceder a través
						de su sitio web
						http://www.erostienda.co/politica-de-privacidad, en el
						correo electrónico soporte@erostienda.co o la línea de
						atención al cliente. Canales estos habilitados para
						ejercer mis derechos.
					</p>
					<p className="mb-3">
						Cualquier modificación al presente aviso será notificada
						a través de los medios dispuestos para ello.
					</p>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default PrivacyScreen;
