import React from 'react';
import { useGetNavQuery } from '../generated/graphql';
import MetodosPago from '../assets/metodos-pago.png';

const Footer: React.FC = () => {
	const { data } = useGetNavQuery();

	return (
		<footer className="relative lg:px-36 md:px-28 px-8 w-full bg-main-gray">
			<div className="pt-16 pb-2 border-b border-gray-600">
				<div className="flex flex-wrap overflow-hidden">
					<div className="overflow-hidden lg:w-1/2 w-full mb-8 px-3">
						<h3 className="text-red-600 text-lg font-bold">
							Contáctenos
						</h3>
						<a
							href={`https://wa.me/${(
								(data?.footers &&
									data.footers[0] &&
									data.footers[0].NumeroWhatsapp) ??
								'573504538519'
							).replaceAll(/(\+| )/g, '')}
							?text=Me%20gustar%C3%ADa%20saber%20el%20precio%20del%20producto`}
							rel="noreferrer"
							target="_blank"
						>
							<svg
								style={{ color: '#41ea54' }}
								xmlns="http://www.w3.org/2000/svg"
								fill="currentColor"
								className="w-7 h-7 inline-block mr-2"
								viewBox="0 0 16 16"
							>
								<path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
							</svg>

							<h4 className="text-base text-yellow-600 font-bold inline-block">
								{(data?.footers &&
									data.footers[0] &&
									data.footers[0].NumeroWhatsapp) ??
									'3122761971'}
							</h4>
						</a>

						<h3 className="text-red-600 text-lg font-bold">
							{(data?.footers &&
								data.footers[0] &&
								data.footers[0].TextoWhatsapp) ??
								'Prueba'}
						</h3>
					</div>

					<div className="overflow-hidden lg:w-1/6 w-1/2 px-3">
						<h3 className="text-red-600 text-lg font-bold capitalize">
							Información
						</h3>
						<a href="/somos">
							<h4 className="text-sm text-white font-bold mb-2 transition-all duration-150 hover:text-main-orange">
								Sobre nosotros
							</h4>
						</a>
						<a href="/faq">
							<h4 className="text-sm text-white font-bold mb-2 transition-all duration-150 hover:text-main-orange">
								FAQ
							</h4>
						</a>
						<a href="/contacto">
							<h4 className="text-sm text-white font-bold transition-all duration-150 hover:text-main-orange">
								Contacto
							</h4>
						</a>
					</div>
					<div className="overflow-hidden lg:w-1/6 w-1/2 px-3">
						<h3 className="text-red-600 text-lg leading-none font-bold capitalize">
							Servicio al cliente
						</h3>
						<a href="/terminos">
							<h4 className="text-sm text-white font-bold leading-6 mb-2 transition-all duration-150 hover:text-main-orange">
								Terminos y condiciones
							</h4>
						</a>
						<a href="/privacidad">
							<h4 className="text-sm text-white font-bold leading-6 transition-all duration-150 hover:text-main-orange">
								Políticas de privacidad
							</h4>
						</a>
					</div>
				</div>
			</div>
			<div className="py-4">
				<div className="w-full">
					<div className="w-1/2 mx-auto my-1">
						<img src={MetodosPago} alt="" />
					</div>
				</div>
				<div className="flex flex-wrap overflow-hidden">
					<div className="w-full overflow-hidden md:w-3/5">
						<h4 className="text-sm text-white font-bold mb-4">
							Eros Boutique 2021 | Todos los derechos reservados |
							Hecho con{' '}
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="currentColor"
								className="text-red-600 w-3 h-3 inline-block"
								viewBox="0 0 16 16"
							>
								<path d="m8 6.236-.894-1.789c-.222-.443-.607-1.08-1.152-1.595C5.418 2.345 4.776 2 4 2 2.324 2 1 3.326 1 4.92c0 1.211.554 2.066 1.868 3.37.337.334.721.695 1.146 1.093C5.122 10.423 6.5 11.717 8 13.447c1.5-1.73 2.878-3.024 3.986-4.064.425-.398.81-.76 1.146-1.093C14.446 6.986 15 6.131 15 4.92 15 3.326 13.676 2 12 2c-.777 0-1.418.345-1.954.852-.545.515-.93 1.152-1.152 1.595L8 6.236zm.392 8.292a.513.513 0 0 1-.784 0c-1.601-1.902-3.05-3.262-4.243-4.381C1.3 8.208 0 6.989 0 4.92 0 2.755 1.79 1 4 1c1.6 0 2.719 1.05 3.404 2.008.26.365.458.716.596.992a7.55 7.55 0 0 1 .596-.992C9.281 2.049 10.4 1 12 1c2.21 0 4 1.755 4 3.92 0 2.069-1.3 3.288-3.365 5.227-1.193 1.12-2.642 2.48-4.243 4.38z" />
							</svg>{' '}
							por
							<a
								href="https://www.ds2.com.co/"
								rel="noreferrer"
								target="_blank"
							>
								DS2 Design
							</a>
						</h4>
					</div>
					<div className="w-full overflow-hidden md:w-2/5">
						<h4 className="text-sm text-white font-bold mb-2">
							Siguenos en nuestras redes sociales
							<a
								href={
									(data?.headers &&
										data.headers[0] &&
										data.headers[0].Facebook) ??
									'https://facebook.com'
								}
								rel="noreferrer"
								target="_blank"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									aria-hidden="true"
									focusable="false"
									role="img"
									className="text-purple-400 inline-block w-6 h-6 mx-2"
									viewBox="0 0 320 512"
								>
									<path
										fill="currentColor"
										d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
									/>
								</svg>
							</a>
							<a
								href={
									(data?.headers &&
										data.headers[0] &&
										data.headers[0].Instagram) ??
									'https://instagram.com'
								}
								rel="noreferrer"
								target="_blank"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									className="text-pink-400 inline-block w-6 h-6"
									viewBox="0 0 16 16"
								>
									<path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
								</svg>
							</a>
						</h4>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
