/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import NavInfo from '../components/NavInfo';

const TermsScreen: React.FC = () => {
	return (
		<>
			<Nav />
			<section className="ptext-sm font-light text-gray-500 pt-6 pb-5 mb-6 lg:mb-8 lg:px-24 md:px-16 px-4">
				<NavInfo path={['Inicio', 'Terminos Y Condiciones']} />
				<h2 className="text-red-600 text-xl font-bold">
					TÉRMINOS Y CONDICIONES GENERALES
				</h2>
				<p className="font-semibold text-lg mb-3 text-main-orange">
					Al ingresar a nuestro sitio web www.erostienda.co el usuario
					y/o comprador acepta sujetarse a los Términos y Condiciones
					Generales, junto con todas las demás políticas y principios
					del mismo. Le recomendamos leer y entender esta sección
					antes de hacer su pedido.
				</p>
				<p className="mb-2">
					LA PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES, LOS
					CUALES TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE PARA EL
					COMPRADOR Y/O USUARIO, DEBERÁ ABSTENERSE DE UTILIZAR EL
					SITIO Y/O LOS SERVICIOS AQUÍ OFRECIDOS.
				</p>
				<p className="mb-2">
					Para todos los efectos legales, los presentes Términos y
					Condiciones Generales aplicarán de forma irrestricta a todos
					los usuarios del sitio web www.erostienda.co siendo
					exigibles desde la permanencia y/o registro en el mencionado
					sitio web, el cual opera bajo el software de licencia
					privativa o copyright a favor de Imperium Technologies
					S.A.S.
				</p>
				<h2 className="text-red-600 text-xl font-bold">
					Definiciones Preliminares
				</h2>
				<p className="mb-3">
					1. <strong>Usuario o Comprador:</strong> La condición de
					usuario o comprador en el sitio web www.erostienda.co, se
					adquiere por el mero hecho de navegar por el sitio web, con
					independencia de que se halla efectuado un registro previo,
					es por ello que, para todos los efectos de estos términos y
					condiciones, cuando se emplee indistintamente las
					expresiones Usuario o Comprador, se está haciendo referencia
					tanto usuarios navegantes como a usuarios registrados.
				</p>
				<p className="mb-2">
					2. <strong>Usuario Navegante:</strong> identifica toda
					persona que, de forma temporal o permanente, ingresa al
					sitio web www.erostienda.co y realiza actos de consulta de
					contenidos, productos y/o de contacto hacia con el equipo de
					servicio al cliente del propietario del sitio web o un
					tercero que lo represente o realiza una compra en el sitio,
					sin que para ello haya realizado un registro previo.
				</p>
				<p className="mb-2">
					3. <strong>Usuario Registrado:</strong> se entiende toda
					persona que ingresa al sitio web www.erostienda.co realiza
					actos de consulta de contenidos, compras de productos y/o de
					contacto con el equipo de servicio al cliente del
					propietario sitio web, pero dichos actos los hace bajo su
					cuenta de usuario, a la cual accederá mediante un registro
					y/o autenticación previa ante el sistema, proceso por medio
					del cual se recopilará una serie de datos personales que
					permitirán validar su identidad, y así garantizar que pueda
					hacer un mejor uso de los servicios de la plataforma y
					permitirnos proveerle experiencias personalizadas.
				</p>
				<p className="mb-2">
					4. <strong>Registro:</strong> acto mediante el cual un
					usuario navegante realiza su inscripción ante el sitio web
					www.erostienda.co y crea su cuenta de usuario.
				</p>
				<p className="mb-2">
					5. <strong>Sitio web o el Sitio:</strong> cuando en los
					presentes Términos y Condiciones, así como en cualquier otro
					documento, se haga referencia al “sitio web”, “el sitio” o
					expresiones similares, se entenderá que se está hablando del
					portal www.erostienda.co o su aplicativo móvil.
				</p>
				<h2 className="text-red-600 text-xl font-bold">
					Restricciones de Uso
				</h2>
				<p className="mb-3">
					Los contenidos del sitio web www.erostienda.co son propiedad
					exclusiva de Imperium Technologies S.A.S. titular de la
					marca Eros y/o cualquier otra que se anuncie en el sitio,
					incluyendo reproducciones de artículos, diseño gráfico,
					logos, imágenes, textos, ilustraciones, publicidad
					fotográfica, o procedimientos análogos a la fotografía,
					producción audiovisual, marcas y otros signos distintivos,
					en cualquiera de los lenguajes de programación utilizados o
					utilizables, así como todo el software de funcionamiento y
					desarrollo del sitio web.
				</p>
				<p className="mb-2">
					Eros es una marca registrada en Colombia, de Propiedad y/o
					licenciada para su explotación por parte de la sociedad
					Imperium Technologies S.A.S bajo las leyes internacionales
					de propiedad intelectual y en especial de las disposiciones
					contenidas en la Decisión 486 de la Comunidad Andina de
					Naciones.
				</p>
				<p className="mb-2">
					La reproducción, distribución, comunicación al público,
					puesta a disposición del público, transformación, cesión y
					cualquier otro acto o modalidad de explotación, en cualquier
					formato digital o físico que no haya sido expresamente
					autorizado por Imperium Technologies S.A.S., quedan
					expresamente prohibidos, so pena de las acciones legales a
					que haya lugar.
				</p>
				<p className="mb-2">
					En el caso de datos o contenidos compartidos y/o facilitados
					por el usuario al sitio web, el usuario concede a Imperium
					Technologies S.A.S., el derecho no exclusivo, exento de
					derechos de autor y/o propiedad intelectual, de forma
					perpetua, irrevocable y totalmente transferible a terceros,
					a utilizar, reproducir, modificar, adaptar, publicar,
					traducir, crear servicios derivados, distribuir y exhibir
					dichas reseñas y comentarios en todo el mundo y en cualquier
					medio de comunicación.
				</p>
				<p className="mb-2">
					El comprador es responsable por la veracidad y precisión de
					los datos ingresados y/o suministrados en el sitio durante
					la realización de las compras y/o el registro en el sitio
					web; así como, en las condiciones de entrega de los
					productos, en consecuencia cualquier error o imprecisión en
					ellos, especialmente en los datos de la dirección o
					nomenclatura para el envío de los productos adquiridos,
					serán de su exclusiva responsabilidad, exonerando a Imperium
					Technologies S.A.S. de cualquier reclamación por esta
					circunstancia, o por productos entregados en direcciones
					erradas por culpa del comprador.
				</p>
				<p className="mb-2">
					Imperium Technologies S.A.S. declara que es ajeno al proceso
					de las transacciones efectuadas por entidades financieras
					y/o bancarias, así como la validación de las mismas, en
					consecuencia, y por no tener calidad de entidad financiera
					y/o bancaria, no se hace responsable de los rechazos de las
					transacciones efectuadas por los compradores, pues el
					proceso de la transacción como tal, es propio de cada
					entidad financiera. Los presente Términos y Condiciones solo
					podrán ser modificados única y exclusivamente por Imperium
					Technologies S.A.S. o el operador del sitio web,
					modificaciones que tendrán vigencia a partir del momento en
					que sean publicados en el sitio web oficial y en casos
					excepcionales cuando medie notificación directa al usuario,
					en los eventos que de acuerdo con la legislación aplicable
					ello resulte necesario.
				</p>
				<p className="mb-2">
					La violación de las normas diseñadas para cumplimiento del
					usuario, faculta a Imperium Technologies S.A.S. para
					suspender cualquier proceso de compra, bloquear o dar por
					terminado la prestación del servicio brindado en el portal
					www.erostienda.co, de forma temporal o definitiva.
				</p>
				<p className="mb-3">Información de Productos y/o Servicios</p>
				<p className="mb-2">
					1. Los precios y tarifas fijadas para cada servicio y/o
					producto, será el anunciado en el sitio al momento de la
					compra, pues dichos precios podrán variar sin previo aviso
					de tiempo en tiempo, por lo que el propietario del sitio no
					está obligado sino al precio publicado al momento de la
					compra.
				</p>

				<p className="mb-2">
					2. Las unidades disponibles para cada producto serán las
					anunciadas de manera visible en el sitio web, por lo que es
					responsabilidad exclusiva de todos los usuarios verificar
					antes de cada compra la existencia de unidades disponibles.
				</p>
				<p className="mb-2">
					3. Las condiciones y costos de envío de los productos serán
					las que se señalen en el sitio web en el apartado de “Plazos
					y Costos de Envío”, las cuales podrán cambiar sin previo
					aviso, por lo que es obligación de cada usuario consultar
					antes de cualquier compra, las condiciones vigentes.
				</p>

				<p className="mb-2">
					4. La información será ofrecida en formatos de texto, PDF,
					audio, video e imagen, según sea su naturaleza y finalidad.
				</p>

				<p className="mb-2">
					El comprador es responsable por la veracidad y precisión de
					los datos ingresados y/o suministrados en el sitio durante
					la realización de las compras y/o el registro en el sitio
					web; así como, en las condiciones de entrega de los
					productos, en consecuencia cualquier error o imprecisión en
					ellos, especialmente en los datos de la dirección o
					nomenclatura para el envío de los productos adquiridos,
					serán de su exclusiva responsabilidad, exonerando a Imperium
					Technologies S.A.S. de cualquier reclamación por esta
					circunstancia, o por productos entregados en direcciones
					erradas por culpa del comprador.
				</p>
				<p className="mb-2">
					Imperium Technologies S.A.S. declara que es ajeno al proceso
					de las transacciones efectuadas por entidades financieras
					y/o bancarias, así como la validación de las mismas, en
					consecuencia, y por no tener calidad de entidad financiera
					y/o bancaria, no se hace responsable de los rechazos de las
					transacciones efectuadas por los compradores, pues el
					proceso de la transacción como tal, es propio de cada
					entidad financiera.
				</p>
				<p className="mb-2">
					Los presente Términos y Condiciones solo podrán ser
					modificados única y exclusivamente por Imperium Technologies
					S.A.S. o el operador del sitio web, modificaciones que
					tendrán vigencia a partir del momento en que sean publicados
					en el sitio web oficial y en casos excepcionales cuando
					medie notificación directa al usuario, en los eventos que de
					acuerdo con la legislación aplicable ello resulte necesario.
					La violación de las normas diseñadas para cumplimiento del
					usuario, faculta a Imperium Technologies S.A.S. para
					suspender cualquier proceso de compra, bloquear o dar por
					terminado la prestación del servicio brindado en el portal
					www.erostienda.co, de forma temporal o definitiva.
				</p>
			</section>
			<Footer />
		</>
	);
};

export default TermsScreen;
