import React, { useState, useEffect } from 'react';
import { NotificationType } from '../types';
import { fancyTimeFormat } from '../utils/formatters';

type NotificationProps = NotificationType & {
	close: () => void;
};

const Notification: React.FC<NotificationProps> = ({
	title,
	text,
	alert_type: type,
	autohide,
	delay,
	close,
}: NotificationProps) => {
	const [time, setTime] = useState(0);
	useEffect(() => {
		const plusOneInterval = setInterval(() => {
			setTime(time + 1);
		}, 1000);
		return () => clearInterval(plusOneInterval);
	}, [time, setTime]);

	useEffect(() => {
		if (time >= delay && autohide) {
			close();
		}
	}, [delay, time, close, autohide]);

	return (
		<div
			className="toast fade show"
			role="alert"
			aria-live="assertive"
			aria-atomic="true"
		>
			<div className="toast-header">
				<span
					role="img"
					className={`
					${type === 'success' ? 'text-green-500' : ''} 
					${type === 'danger' ? 'text-red-500' : ''} 
					${type === 'warning' ? 'text-yellow-300' : ''}
					${type === 'info' ? 'text-blue-500' : ''} 
					rounded mr-2
					`}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						style={{ width: '1rem', height: '1rem' }}
						fill="currentColor"
						viewBox="0 0 16 16"
					>
						{type === 'success' && (
							<path
								fillRule="evenodd"
								d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
							/>
						)}
						{type === 'danger' && (
							<path
								fillRule="evenodd"
								d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
							/>
						)}
						{type === 'warning' && (
							<path
								fillRule="evenodd"
								d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
							/>
						)}
						{type === 'info' && (
							<path
								fillRule="evenodd"
								d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
							/>
						)}
					</svg>
				</span>
				<strong className="me-auto">{title}</strong>
				<small className="text-muted">
					{time >= 1
						? `Hace ${fancyTimeFormat(time)}`
						: 'Justo ahora'}
				</small>
				<button
					type="button"
					className="btn-close"
					onClick={close}
					aria-label="Cerrar"
				/>
			</div>
			<div className="toast-body">{text}</div>
		</div>
	);
};

export default Notification;
