import React from 'react';

type Path = string[];
type NavInfoProps = {
	path: Path;
};
const NavInfo: React.FC<NavInfoProps> = ({ path }: NavInfoProps) => {
	return (
		<section className="w-full bg-white">
			{path.map((pathName, idx) => {
				return (
					<p
						className={`text-sm inline-block ${
							idx === 0 ? 'text-gray-700' : 'text-semibold'
						}`}
						// eslint-disable-next-line react/no-array-index-key
						key={idx}
					>
						{pathName}
						<span className="mx-1 text-base">
							{idx < path.length - 1 && '>'}
						</span>
					</p>
				);
			})}
		</section>
	);
};

export default NavInfo;
